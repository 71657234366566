import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import '../../../css/views/public/Entorn.css';

import useScrollPosition from "../../../components/elements/useScrollPosition";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Edit from "../Edit";
import ButtonAdmin from "../ButtonAdmin";
import DeleteIcon from '@mui/icons-material/Delete';

function RutaEdit(props) {
    const [open, setOpen] = useState(true);
    const [animation, setAnimation] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
		setTimeout(() => {
			setAnimation('Portada-expand');
		}, 500);
        setTimeout(() => {

            setTitle('title-expand');
		}, 500);
        
    }, []);

    const scrollPosition = useScrollPosition();

    useEffect(() => {
        let elementsToShowLeft = document.querySelectorAll(".show-on-scroll-left");
        elementsToShowLeft.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollLeft');
            }
        })
        let elementsToShowRight = document.querySelectorAll(".show-on-scroll-right");
        elementsToShowRight.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollRight');
            }
        })
        
    }, [scrollPosition]);

    function onClickEliminarEntorn () {

    }
	
    //const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

    if (props.isLeft) {
        return (
            <Box className='Rutes-section' sx={{maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 'unset', paddingRight: 'unset'}}>
                <div style={{position: 'relative', top: '0', left: '0', background:'white', padding: '0.5rem 0rem 0.5rem 0.5rem', display: 'flex', justifyContent:'center', alignItems:'center', borderRadius: '0.4rem'}}>
                    <ButtonAdmin 
                        text={''} 
                        icon={<DeleteIcon />} 
                        variant='' 
                        action={() => {onClickEliminarEntorn(props.data.id)}} 
                        setOpenDeleteModal={props.setOpenDeleteModal}
                        endPoint={'eliminarRuta'}
                        setEndPoint={props.setEndPoint}
                        id={props.data.id}
                        setId={props.setId}
                        folder={'ruta'}
                        setFolder={props.setFolder}
                    />
                </div>
                <div className="Entorn-section-left show-on-scroll-left">
                    <div style={{position: 'absolute', top: '5%', left: '5%'}}>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.imatge} setInitialValue={props.setInitialValue} name={'imatge'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'image'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>
                    </div>
                    <img src={url+props.data?.imatge} className='Entorn-img'/>
                </div>
                <div className="Entorn-section-right show-on-scroll-right">
                    <div style={{display: 'flex'}}>
                        <Typography variant='h3' className="Entorn-title" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.data?.titol}</Typography>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.titol} setInitialValue={props.setInitialValue} name={'titol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Títol' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>
                    </div>
                        
                    <div className="Entorn-separator"></div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{'Distància:'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.distancia}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.distancia} setInitialValue={props.setInitialValue} name={'distancia'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Distància' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>

                    </div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{'Desnivell:'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.desnivell}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.desnivell} setInitialValue={props.setInitialValue} name={'desnivell'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Desnivell' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>

                    </div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{'Dificultat:'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.dificultat}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.dificultat} setInitialValue={props.setInitialValue} name={'dificultat'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Dificultat' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>

                    </div>
                    <Edit endPoint='actualitzarRuta' initialValue={props.data?.text} setInitialValue={props.setInitialValue} name={'text'} setName={props.setName} setEndPoint={props.setEndPoint} color='#333' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>
                    <Typography dangerouslySetInnerHTML={{__html: props.data?.text}} variant='body1' className='Entorn-text' marginBottom='30px'></Typography>
                    <div style={{display: 'flex', alignItems:'center', cursor:'pointer', position: 'relative'}}>
                        <a href={props.data?.link} style={{display: 'flex', alignItems:'center', cursor:'pointer', position: 'relative'}}>
                            <KeyboardArrowLeftIcon />
                            <Typography marginRight={'10px'} variant='h5' className='Entorn-text' cursor={'pointer'}>{'Veure ruta'}</Typography>
                        </a>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.link} setInitialValue={props.setInitialValue} name={'link'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Enllaç' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>

                    </div>
                    
                </div>
            </Box>
        )
    } else {
        return (
            <Box className='Rutes-section' sx={{maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 'unset', paddingRight: 'unset'}}>
                <div style={{position: 'relative', top: '0', left: '0', background:'white', padding: '0.5rem 0rem 0.5rem 0.5rem', display: 'flex', justifyContent:'center', alignItems:'center', borderRadius: '0.4rem'}}>
                    <ButtonAdmin 
                        text={''} 
                        icon={<DeleteIcon />} 
                        variant='' 
                        action={() => {onClickEliminarEntorn(props.data.id)}} 
                        setOpenDeleteModal={props.setOpenDeleteModal}
                        endPoint={'eliminarRuta'}
                        setEndPoint={props.setEndPoint}
                        id={props.data.id}
                        setId={props.setId}
                        folder={'Ruta'}
                        setFolder={props.setFolder}
                    />
                </div>
                <div className="Entorn-section-left show-on-scroll-left" style={{textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <div style={{display: 'flex'}}>
                        <Typography variant='h3' className="Entorn-title" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.data?.titol}</Typography>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.titol} setInitialValue={props.setInitialValue} name={'titol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Títol' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>
                    </div>
                    <div className="Entorn-separator"></div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{'Distància:'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.distancia}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.distancia} setInitialValue={props.setInitialValue} name={'distancia'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Distància' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>

                    </div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{'Desnivell:'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.desnivell}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.desnivell} setInitialValue={props.setInitialValue} name={'desnivell'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Desnivell' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>

                    </div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{'Dificultat:'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.dificultat}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.dificultat} setInitialValue={props.setInitialValue} name={'dificultat'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Dificultat' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>

                    </div>
                    <Edit endPoint='actualitzarRuta' initialValue={props.data?.text} setInitialValue={props.setInitialValue} name={'text'} setName={props.setName} setEndPoint={props.setEndPoint} color='#333' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>
                    <Typography dangerouslySetInnerHTML={{__html: props.data?.text}} variant='body1' className='Entorn-text' marginBottom='30px'></Typography>
                    <div style={{display: 'flex', alignItems:'center', cursor:'pointer', position: 'relative'}}>
                        <a href={props.data?.link} style={{display: 'flex', alignItems:'center', cursor:'pointer', position: 'relative'}}>
                            <KeyboardArrowLeftIcon />
                            <Typography marginRight={'10px'} variant='h5' className='Entorn-text' cursor={'pointer'}>{'Veure ruta'}</Typography>
                        </a>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.link} setInitialValue={props.setInitialValue} name={'link'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Enllaç' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>

                    </div>
                    
                </div>
                <div className="Entorn-section-right show-on-scroll-right" style={{position: 'relative'}}>
                    <div style={{position: 'absolute', top: '5%', right: '5%'}}>
                        <Edit endPoint='actualitzarRuta' initialValue={props.data?.imatge} setInitialValue={props.setInitialValue} name={'imatge'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'image'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'ruta'} setFolder={props.setFolder}/>
                    </div>
                    <img src={url+props.data?.imatge} className='Entorn-img'/>
                    <Typography variant='h2' className="Entorn-title " textTransform='capitalize' color='#1D1D1B' fontWeight='800' style={{position: 'absolute', top: '10%', right: '40%'}}></Typography>
                </div>
            </Box> 
	    );
    }
	
}

export default RutaEdit;
