import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Casa.css';
import image from '../../assets/img/fotos/20.jpg';
import baixa from '../../assets/img/fotos/34.jpg'
import porxo from '../../assets/img/fotos/24.jpg';
import primera from '../../assets/img/fotos/20.jpg';
import segona from '../../assets/img/fotos/33.jpg';
import exterior from '../../assets/img/fotos/35.jpg';
import { ReactComponent as Icon } from "../../assets/iconos.svg";
import { ReactComponent as IconInt } from "../../assets/iconos2.svg";
import Footer from "../../layouts/public/Footer";
import Caracteristiques from "./Caracteristiques";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useScrollPosition from "../../components/elements/useScrollPosition";
import ScrollToTop from "react-scroll-to-top";
import Idioma from "../../components/elements/Idioma";
import Section from "./Section";

function Casa(props) {
    const [open, setOpen] = useState(true);
    const [animation, setAnimation] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
		setTimeout(() => {
			setAnimation('Portada-expand');
		}, 500);
        setTimeout(() => {

            setTitle('title-expand');
		}, 500);
        
    }, []);

    const scrollPosition = useScrollPosition();

    useEffect(() => {
        let elementsToShowLeft = document.querySelectorAll(".show-on-scroll-left");
        elementsToShowLeft.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollLeft');
            }
        })
        let elementsToShowRight = document.querySelectorAll(".show-on-scroll-right");
        elementsToShowRight.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollRight');
            }
        })
        
    }, [scrollPosition]);
    
    function onClickShow() {
        setOpen(!open);
    }
	
    // const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

	return (
		<Page title="El Pinatell - La Casa">
			<Box className='Casa-portada'>
                <div className="Home-idiomes">
					<Idioma selected={props.idioma} f_setSelected={props.f_setIdioma} idiomes={props.idiomes}/>
				</div>
                <div className={"Portada-line " + animation}></div>
				<div className="Casa-image">
					<img src={url+props.masia?.masia_image} className='Casa-img'/>
				</div>
				<div className="Casa-background"></div>
				<Box className={'Casa-content ' + title}>
					<Typography variant='h2' className="Casa-title" color='#fff' textTransform='unset'>{props.masia?.masia_titol}</Typography>
				</Box>
			</Box>
            <Box className='Casa-section'>
                <Typography variant='h1' className="Casa-title" textTransform='capitalize' color='#1D1D1B'>Masia El Pinatell</Typography>
                <div className="Casa-separator"></div>
                <Typography dangerouslySetInnerHTML={{__html: props.masia?.descripcio}} variant='body1' className='Casa-text' marginBottom='30px'></Typography>
                <div style={{display: 'flex', marginBottom: '20px'}}>
                    <Typography variant='h4' className="Casa-title" fontSize='24px !important' width='unset !important' textTransform='unset' color='#6F8A30 !important' fontWeight='800 !important'>{props.masia?.masia_caracteristiques}</Typography>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}} onClick={onClickShow}>{open ? <ExpandLessIcon htmlColor="#6F8A30" sx={{cursor: 'pointer'}}/> : <ExpandMoreIcon htmlColor="#6F8A30" sx={{cursor: 'pointer'}}/>}</div>
                </div>
                <Caracteristiques data={props.masia?.caracteristiques} opened={open}/>
                <Icon />
                <Typography dangerouslySetInnerHTML={{__html: props.masia?.masia_inclou}} variant='body1' className='Casa-text' marginTop='30px'></Typography>
            </Box>
            {props.masia?.espais.map((espai, index) => {
                return (
                    <Section isLeft={index % 2 == 0 ? true : false} data={espai} />
                )
            })}
            <ScrollToTop smooth/>
            <Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default Casa;
