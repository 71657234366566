import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/public/Entorn.css';
import image from '../../../assets/img/entorn.jpg';
import roures from '../../../assets/img/roures.jpeg';
import estela from '../../../assets/img/estela.jpg';
import roc from '../../../assets/img/roc.jpg';
import rasos from '../../../assets/img/rasos.jpeg';
import figuerassa from '../../../assets/img/figuerassa.webp';
import berga from '../../../assets/img/berga.jpeg';
import queralt from '../../../assets/img/queralt.jpeg';
import bici from '../../../assets/img/bici.jpg';
import Footer from "../../../layouts/public/Footer";
import PlaceEdit from "./PlaceEdit";
import Idioma from "../../../components/elements/Idioma";

import useScrollPosition from "../../../components/elements/useScrollPosition";
import ScrollToTop from "react-scroll-to-top";
import Edit from "../Edit";
import ButtonAdmin from "../ButtonAdmin";

import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import PinatellIcon from "../../../components/elements/PinatellIcon";


function EntornEdit(props) {
	

	const Background = (props) => {
		const [title, setTitle] = useState("L'Entorn");

	const [animation, setAnimation] = useState("");
	const scrollPosition = useScrollPosition();
	const [line, setLine] = useState('');
    const [titleAni, setTitleAni] = useState('');
    const [titles, setTitles] = useState([]);
	const navigate = useNavigate();

    useEffect(() => {
		setTimeout(() => {
			setLine('Portada-expand');
		}, 500);
        setTimeout(() => {

            setTitleAni('title-expand');
		}, 500);
        let titols = ['Entorn'];
		props.masia?.entorns.map((entorn) => {
			titols.push(entorn.titol);
		})
		setTitles(titols);
    }, []);

	useEffect(() => {
		let height = window.innerHeight;

		let elementsToShowLeft = document.querySelectorAll(".show-on-scroll-left");
        elementsToShowLeft.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollLeft');
            }
        })
        let elementsToShowRight = document.querySelectorAll(".show-on-scroll-right");
        elementsToShowRight.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollRight');
            }
        })



		
        
    }, [scrollPosition]);

	function onClickAfegirEntorn() {
        navigate(props.masia.id+'/nou');
	}

	// const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

		return (
			<Box>
				<div className="Entorn-fixed" style={{width: '84vw', display: 'flex', justifyContent: 'center'}}>
					<Typography variant='h2' className={"Entorn-title-fixed " + animation} maxWidth={'80%'} textAlign='center' textTransform='capitalize' color='#1D1D1B' fontWeight='800'><PinatellIcon extraClass='Pinatell-black'/></Typography>
				</div>
				<div style={{marginBottom: '116px'}}>
					<Box className="Entorn-portada">
						<div className="Home-idiomes">
							<Idioma selected={props.idioma} f_setSelected={props.f_setIdioma} idiomes={props.idiomes}/>
						</div>
						<div className="Entorn-image" style={{position: 'relative'}}>
							<img src={url+props.masia?.entorn_image} className='Entorn-img'/>
						</div>
						<div className="Entorn-background"></div>
						<Box className={'Entorn-content ' + titleAni}>
							<div style={{display: 'flex', justifyContent: 'center'}}>
								<Typography variant='h1' className="Entorn-title" color='#fff' textTransform='unset'>{props.masia?.entorn_titol}</Typography>
								<Edit endPoint='actualitzarMasia' initialValue={props.masia?.entorn_titol} setInitialValue={props.setInitialValue} name={'entorn_titol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
							</div>
							<Typography variant='h2' className='Entorn-text' color='#fff !important' marginTop='30px' textTransform='unset'></Typography>
						</Box>
						<div className={"Portada-line " + line}></div>
						<div style={{position: 'absolute', top: '5%', left: '5%'}}>
							<Edit endPoint='actualitzarMasia' initialValue={props.masia?.entorn_image} setInitialValue={props.setInitialValue} name={'entorn_image'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'image'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						</div>
					</Box>
					<div style={{padding: '0.5rem 0rem 0.5rem 0.5rem', display: 'flex', justifyContent:'center', alignItems:'center', borderRadius: '0.4rem'}}>
						<ButtonAdmin text={'Afegir Entorn'} icon={<AddIcon />} style={{marginTop: '60px'}} action={onClickAfegirEntorn}/>
					</div>

					{props.masia?.entorns.map((entorn, index) => {
						return (
							<PlaceEdit isLeft={index % 2 == 0 ? true : false} data={entorn} setOpenDeleteModal={props.setOpenDeleteModal} setInitialValue={props.setInitialValue} setName={props.setName} setEndPoint={props.setEndPoint} setOpenModal={props.setOpenModal} setType={props.setType} setLabel={props.setLabel} setId={props.setId} setFolder={props.setFolder}/>
						)
					})}
				</div>
			</Box>
		)
	}

	
	return (
		<Page title="El Pinatell">
			<Background idioma={props.idioma} f_setIdioma={props.f_setIdioma} idiomes={props.idiomes} isMobile={props.isMobile} masia={props.masia} setOpenDeleteModal={props.setOpenDeleteModal}  setInitialValue={props.setInitialValue} setName={props.setName} setEndPoint={props.setEndPoint} setOpenModal={props.setOpenModal} setType={props.setType} setLabel={props.setLabel} setId={props.setId} setFolder={props.setFolder}/>

            <ScrollToTop smooth/>
			<Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default EntornEdit;