import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import { ReactComponent as Mail } from "../../../assets/mail.svg";
import { ReactComponent as Phone } from "../../../assets/phone.svg";
import { ReactComponent as Ubi } from "../../../assets/ubi.svg";
import '../../../css/views/public/Galeria.css';
import image1 from '../../../assets/img/fotos/1.jpg';
import image2 from '../../../assets/img/fotos/2.jpg';
import image3 from '../../../assets/img/fotos/3.jpg';
import image4 from '../../../assets/img/fotos/4.jpg';
import image5 from '../../../assets/img/fotos/5.jpg';
import image6 from '../../../assets/img/fotos/6.jpg';
import image7 from '../../../assets/img/fotos/7.jpg';
import image8 from '../../../assets/img/fotos/8.jpg';
import image9 from '../../../assets/img/fotos/9.jpg';
import image10 from '../../../assets/img/fotos/10.jpg';
import image11 from '../../../assets/img/fotos/11.jpg';
import image12 from '../../../assets/img/fotos/12.jpg';
import image13 from '../../../assets/img/fotos/13.jpg';
import image14 from '../../../assets/img/fotos/14.jpg';
import image15 from '../../../assets/img/fotos/15.jpg';
import image16 from '../../../assets/img/fotos/16.jpg';
import image17 from '../../../assets/img/fotos/17.jpg';
import image18 from '../../../assets/img/fotos/18.jpg';
import image19 from '../../../assets/img/fotos/19.jpg';
import image20 from '../../../assets/img/fotos/20.jpg';
import image21 from '../../../assets/img/fotos/21.jpg';
import image22 from '../../../assets/img/fotos/22.jpg';
import image23 from '../../../assets/img/fotos/23.jpg';
import image24 from '../../../assets/img/fotos/24.jpg';
import image25 from '../../../assets/img/fotos/25.jpg';
import image26 from '../../../assets/img/fotos/26.jpg';
import image27 from '../../../assets/img/fotos/27.jpg';
import image28 from '../../../assets/img/fotos/28.jpg';
import image29 from '../../../assets/img/fotos/29.jpg';
import image30 from '../../../assets/img/fotos/30.jpg';
import image31 from '../../../assets/img/fotos/31.jpg';
import image32 from '../../../assets/img/fotos/32.jpg';
import image33 from '../../../assets/img/fotos/33.jpg';
import image34 from '../../../assets/img/fotos/34.jpg';
import image35 from '../../../assets/img/fotos/35.jpg';
import Footer from "../../../layouts/public/Footer";
import Calendari from "../../../components/elements/Calendari";
import PinatellCarouselEdit from "./PinatellCarouselEdit";

function GaleriaEdit(props) {

    

	const [title, setTitle] = useState('interior');
	const [images, setImages] = useState([]);

	useEffect(() => {
        let imatges = props.masia?.imatges;
		let aux = [];
		for (let i=0 ; i < imatges?.length ; i++) {
			if (imatges[i].tipus == title) {
				aux.push(imatges[i])
			}
		}
		setImages(aux);
    }, [props.masia]);

	useEffect(() => {
        let imatges = props.masia?.imatges;
		let aux = [];
		for (let i=0 ; i < imatges?.length ; i++) {
			if (imatges[i].tipus == title) {
				aux.push(imatges[i])
			}
		}
		setImages(aux);
    }, [title]);

	return (
		<Page title="El Pinatell">
			<Box className='Galeria-portada'>
                <div className={"PinatellCarousel-background " }></div>
                <PinatellCarouselEdit masia={props.masia} imatges={images} hideMenu={props.hideMenu} setHideMenu={props.setHideMenu} title={title} f_setTitle={setTitle} isMobile={props.isMobile}/>
			</Box>
		</Page>
	);
}

export default GaleriaEdit;