import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import theme from "./theme";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { Grow, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
// import { getUser } from "./API/API";
import Loading from "./components/layouts/Loading";
import { getElement, getElements, getUser } from "./API/API";
import EditModal from "./views/admin/EditModal";
import DeleteModal from "./views/admin/DeleteModal";
import { useLocalStorage } from "usehooks-ts";
import Cookies from "./components/elements/Cookies";

function App() {
	const notistackRef = React.createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};
	const [logged, setLogged] = useState(true);
    const [isMobile, setIsMobile] = useState(getIsMobile());
	const [loading, setLoading] = useState(false);
	const [hideMenu, setHideMenu] = useState(false);
	
    const [idiomes, setIdiomes] = useState([]);
    const [idioma, setIdioma] = useState(null);
	const [masia, setMasia] = useState();

	const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

	const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);

    const [type, setType] = useState('');
    const [folder, setFolder] = useState('');
    const [name, setName] = useState('');
    const [id, setId] = useState(null);
    const [label, setLabel] = useState('');
    const [endPoint, setEndPoint] = useState('');
    const [initialValue, setInitialValue] = useState(null);

	const [actualitzar, setActualitzar] = useState(false);

	const [acceptCookies, setAcceptCookies] = useLocalStorage("acceptCookies", false);

	const routing = useRoutes(routes(logged, isMobile, hideMenu, setHideMenu, idioma, setIdioma, idiomes, masia, type, setType, folder, setFolder, name, setName, id, setId, label, setLabel, endPoint, setEndPoint, initialValue, setInitialValue, setOpenModal, setOpenDeleteModal, actualitzar, setActualitzar));

	function getIsMobile() {
        return window.innerWidth < 1024;
    }

	useEffect(() => {
		const getIdiomes = async () => {
			const { result } = await getElements("idiomes");
			setIdiomes(result);
			setIdioma(result[0]?.id);
		};
		if (getIdiomes.length == 0) {
			getIdiomes();
		}

	}, []);

	useEffect(() => {
		const getMasia = async () => {
			const { result } = await getElement("masia", idioma);
			setMasia(result);
		};
		if (idioma != null) {
			getMasia();
		}

	}, [idioma]);

	useEffect(() => {
		const getMasia = async () => {
			const { result } = await getElement("masia", idioma);
			setMasia(result);
			setActualitzar(false);
		};
		if (actualitzar == true) {
			getMasia();
		}

	}, [actualitzar]);

	useEffect(() => {
		const get = async () => {
			const userExist = JSON.parse(localStorage.getItem("user"));
			if (userExist) {
				const { message, data } = await getUser();
				if (message === 200) {
					//userExist.role_id = data.role_id;
					localStorage.setItem("isLoggedIn", true);
					localStorage.setItem("user", JSON.stringify(userExist));
					setLogged(true);
					setLoading(false);
				} else {
					setLogged(false);
					setLoading(false);
				}
			} else {
				setLogged(false);
				setLoading(false);
			}
		};
		get();
	}, [routing]);

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				TransitionComponent={Grow}
				ref={notistackRef}
				action={(key) => (
					<IconButton onClick={onClickDismiss(key)}>
						<Close style={{ color: "white" }} />
					</IconButton>
				)}
			>
				<Loading loading={loading}>{routing}</Loading>
				<Cookies acceptCookies={acceptCookies} setAcceptCookies={setAcceptCookies} setHideMenu={setHideMenu}/>

                <EditModal 
                    open={openModal}
                    setOpen={setOpenModal}
                    handleOpenModal={handleOpenModal}
                    handleCloseModal={handleCloseModal}
                    type={type}
                    name={name}
                    label={label}
                    endPoint={endPoint}
                    id={id}
                    folder={folder}
                    initialValue={initialValue}
					setActualitzar={setActualitzar}
                />
				<DeleteModal 
                    open={openDeleteModal}
                    setOpen={setOpenDeleteModal}
                    handleOpenModal={handleOpenDeleteModal}
                    handleCloseModal={handleCloseDeleteModal}
                    type={type}
                    name={name}
                    label={label}
                    endPoint={endPoint}
                    id={id}
                    folder={folder}
                    initialValue={initialValue}
					setActualitzar={setActualitzar}
                />
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
