import * as React from 'react';
import ButtonMUI from '@mui/material/Button';

function ButtonAdmin(props) {

  const { variant = 'outlined', action = null } = props;

  function onClickButton(action) {
    if (props.setOpenDeleteModal) {
        props.setId(props.id);
        props.setEndPoint(props.endPoint);
        if (props.setFolder != undefined) {

            props.setFolder(props.folder);
        }
        props.setOpenDeleteModal(true);
    } else {
        action();
    }
  }

  return (
      <ButtonMUI variant={variant} startIcon={props.icon} sx={props.style} onClick={()=>{onClickButton(action)}}>
        {props.text}
      </ButtonMUI>
  );
}

export default ButtonAdmin;