import React, { useEffect, useState } from "react";
import '../../css/views/public/Idioma.css';
import { getElements } from "../../API/API";

const Idioma = (props) => {

    function onClickIdioma(idioma) {
        props.f_setSelected(idioma);
    }

	return (
		<div className={"Idioma-container"}>
            {props.idiomes?.map((idioma) => {
                return (
                    <div key={idioma.id} className="Idioma-item-container">
                        <span className="Idioma-item" onClick={()=> {onClickIdioma(idioma.id)}} style={{fontWeight: (props.selected == idioma?.id ? 'bold' : 'normal')}}>{idioma.lan}</span>
                    </div>
                )
            })}
		</div>
	)
}

export default Idioma;