import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import '../../css/views/public/Entorn.css';
import roures from '../../assets/img/roures.jpeg';
import estela from '../../assets/img/estela.jpg';

import useScrollPosition from "../../components/elements/useScrollPosition";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

function Ruta(props) {
    const [open, setOpen] = useState(true);
    const [animation, setAnimation] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
		setTimeout(() => {
			setAnimation('Portada-expand');
		}, 500);
        setTimeout(() => {

            setTitle('title-expand');
		}, 500);
        
    }, []);

    const scrollPosition = useScrollPosition();

    useEffect(() => {
        let elementsToShowLeft = document.querySelectorAll(".show-on-scroll-left");
        elementsToShowLeft.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollLeft');
            }
        })
        let elementsToShowRight = document.querySelectorAll(".show-on-scroll-right");
        elementsToShowRight.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollRight');
            }
        })
        
    }, [scrollPosition]);
	
    // const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

    if (props.isLeft) {
        return (
            <Box className='Rutes-section' sx={{maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 'unset', paddingRight: 'unset'}}>
                <div className="Entorn-section-left show-on-scroll-left">
                    <img src={url+props.data?.imatge} className='Entorn-img'/>
                </div>
                <div className="Entorn-section-right show-on-scroll-right">
                    <Typography variant='h3' className="Entorn-title" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.data?.titol}</Typography>
                        
                    <div className="Entorn-separator"></div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.masia.rutes_distancia + ':'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.distancia}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                    </div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.masia.rutes_desnivell + ':'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.desnivell}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                    </div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.masia.rutes_dificultat + ':'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.dificultat}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                    </div>
                    <Typography dangerouslySetInnerHTML={{__html: props.data?.text}} variant='body1' className='Entorn-text' marginBottom='30px'></Typography>
                    <a href={props.data?.link} style={{display: 'flex', alignItems:'center', cursor:'pointer', position: 'relative'}}>
                        <KeyboardArrowLeftIcon />
                        <Typography marginRight={'10px'} variant='h5' className='Entorn-text' cursor={'pointer'}>{'Veure ruta'}</Typography>
                    </a>
                    
                </div>
            </Box>
        )
    } else {
        return (
            <Box className='Rutes-section' sx={{maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 'unset', paddingRight: 'unset'}}>
                
                <div className="Entorn-section-left show-on-scroll-left" style={{textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <Typography variant='h3' className="Entorn-title" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.data?.titol}</Typography>
                    <div className="Entorn-separator"></div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.masia.rutes_distancia + ':'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.distancia}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                    </div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.masia.rutes_desnivell + ':'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.desnivell}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                    </div>
                    <div style={{display: 'flex', alignItems:'center', marginBottom: '20px'}}>
                        <Typography variant='body1' className="Entorn-text" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.masia.rutes_dificultat + ':'}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: props.data?.dificultat}} marginLeft={'20px'} variant='body1' textTransform={'unset'} className='Entorn-text'></Typography>
                    </div>
                    <Typography dangerouslySetInnerHTML={{__html: props.data?.text}} variant='body1' className='Entorn-text' marginBottom='30px'></Typography>
                    <a href={props.data?.link} style={{display: 'flex', alignItems:'center', position: 'relative'}}>
                        <Typography marginRight={'10px'} variant='h5' className='Entorn-text' cursor={'pointer'}>{'Veure ruta'}</Typography>
                        <KeyboardArrowRightIcon />
                    </a>
                    
                </div>
                <div className="Entorn-section-right show-on-scroll-right" style={{position: 'relative'}}>
                    
                    <img src={url+props.data?.imatge} className='Entorn-img'/>
                    <Typography variant='h2' className="Entorn-title " textTransform='capitalize' color='#1D1D1B' fontWeight='800' style={{position: 'absolute', top: '10%', right: '40%'}}></Typography>
                </div>
            </Box> 
	    );
    }
	
}

export default Ruta;
