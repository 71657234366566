import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Berga = (props) => {
    const { SvgIcon, subSvgIcon, button, extraClass, onClickIcon, icon } = props;
    return (
        <svg className={extraClass} onClick={()=>{onClickIcon(icon)}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197.21 111.23">
            <g id="Capa_2" data-name="Capa 2">
                <g id="Tect">
                    <rect class="icon-map-color" x="84.45" y="4.28" width="26.66" height="26.66" rx="3" transform="translate(41.09 -63.98) rotate(45)"/>
                    <path class="icon-map-color" d="M0,36.65a58.17,58.17,0,0,1,6.37.41,63.93,63.93,0,0,1,8.27-.82c12.81,0,22.07,5.78,22.07,14.47,0,6.2-4.88,10.75-13.81,12.4,11.33,1.65,19,7.77,19,16.54,0,9.51-6.69,15.29-23.23,15.29-1.49,0-5.79-.08-7.53-.16-3.39-.17-9.09-.25-9.09-6.62V44.59c0-3.56-.33-7.36-2.07-7.36Zm9.1,1.57V62a43.86,43.86,0,0,0,6.36.5c9.18,0,13.81-4.47,13.81-11.66,0-8.18-5.87-13.39-14.06-13.39A35.37,35.37,0,0,0,9.1,38.22Zm0,26.38V86.51c0,2.56.16,5.37.91,6.7a60.49,60.49,0,0,0,7.52.49c11.16,0,16.95-5.12,16.95-13.89,0-9.84-7.11-16-16.95-16A52.49,52.49,0,0,0,9.1,64.6Z"/>
                    <path class="icon-map-color" d="M69.46,55.25c9.59,0,14,7.36,14,16.13H59a23.49,23.49,0,0,0-.09,2.56c0,10.67,4.3,17,11.91,17,4.71,0,8.6-2.56,11.91-6.11l.49.41c-3.47,5.29-8.93,9.67-15.79,9.67-9.26,0-14.72-7.44-14.72-19.26S58.87,55.25,69.46,55.25ZM79.71,70.14c-.33-7.69-3.39-13.65-10.42-13.65-5.87,0-9.42,5.3-10.25,13.65Z"/>
                    <path class="icon-map-color" d="M92.53,56.25v-.58c2.89,0,7.36.91,8.18,5.87,2.4-5.21,9-9,17-4l-.25.58c-9.84-4.3-16.71,4.88-16.71,14.55V86.51c0,3.55.34,7.44,2.07,7.44v.58c-3.14,0-8.27-1.07-8.27-7.44V63.69C94.59,60.13,94.26,56.25,92.53,56.25Z"/>
                    <path class="icon-map-color" d="M124.36,101.15c0-4.47,6.62-6.87,13.06-6.62v.58A6.75,6.75,0,0,0,131,102c0,5.13,4,8,10.91,8,7.77,0,11.08-4.88,11.08-9.67,0-12.9-21.5-5.54-21.5-15.71,1.82-.91,5.38-3.39,7.86-4.72-8.77-.74-13.15-5.7-13.15-12.48,0-9.18,8.27-12.16,16.12-12.16,2-3.39,8.36-7.52,13.23-7.52,3,0,4.88,1.32,4.88,4.46a24.22,24.22,0,0,0-6.86-1c-4.05,0-7.86,1.16-10.09,4.14,8.6.66,13.73,6.28,13.73,12.07,0,7.36-5.05,12.57-15.47,12.57h-1.07c-1.74,1.07-3.64,2.81-3.64,3.55,0,4.55,22.16-.16,22.16,14.72,0,7.53-5.62,13-17.28,13C131.22,111.23,124.36,107.43,124.36,101.15Zm8.43-33.74c0,6.7,2.65,11.33,8.93,11.33s8.85-4.63,8.85-11.33c0-5.62-3.31-10.92-8.93-10.92C135.85,56.49,132.79,60.79,132.79,67.41Z"/>
                    <path class="icon-map-color" d="M168.27,85.77c0-9.18,13.48-11.91,20.67-13.65V67.24c0-5.95-1.24-11.16-6.45-11.16a11.28,11.28,0,0,0-10.75,7.19l-.58-.41a11.92,11.92,0,0,1,11.58-7.61c6.28,0,12.4,3.89,12.4,11.75V86.51c0,3.55.33,7.44,2.07,7.44v.58c-2.81,0-7.2-.83-8.11-5.54-2.14,3.06-5.12,6-10.5,6C172,94.94,168.27,90.56,168.27,85.77Zm13.06,7.6c6,0,7.61-8,7.61-13.56V73c-6.45,2.31-14.22,4.55-14.22,12.73C174.72,90.73,177.69,93.37,181.33,93.37Z"/>
                </g>
            </g>
        </svg>
        
    );
};

export default Berga;