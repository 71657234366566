import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/public/Casa.css';
import image from '../../../assets/img/fotos/20.jpg';
import baixa from '../../../assets/img/fotos/34.jpg'
import porxo from '../../../assets/img/fotos/24.jpg';
import primera from '../../../assets/img/fotos/20.jpg';
import segona from '../../../assets/img/fotos/33.jpg';
import exterior from '../../../assets/img/fotos/35.jpg';
import { ReactComponent as Icon } from "../../../assets/iconos.svg";
import { ReactComponent as IconInt } from "../../../assets/iconos2.svg";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScrollToTop from "react-scroll-to-top";
import useScrollPosition from "../../../components/elements/useScrollPosition";
import Caracteristiques from "../../public/Caracteristiques";
import Idioma from "../../../components/elements/Idioma";
import SectionEdit from "./SectionEdit";
import Footer from "../../../layouts/public/Footer";
import Edit from "../Edit";
import ButtonAdmin from "../ButtonAdmin";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import CaracteristiquesEdit from "./CaracteristiquesEdit";

function CasaEdit(props) {
    const [open, setOpen] = useState(true);
    const [animation, setAnimation] = useState('');
    const [title, setTitle] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
		setTimeout(() => {
			setAnimation('Portada-expand');
		}, 500);
        setTimeout(() => {

            setTitle('title-expand');
		}, 500);
        
    }, []);

    const scrollPosition = useScrollPosition();

    useEffect(() => {
        let elementsToShowLeft = document.querySelectorAll(".show-on-scroll-left");
        elementsToShowLeft.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollLeft');
            }
        })
        let elementsToShowRight = document.querySelectorAll(".show-on-scroll-right");
        elementsToShowRight.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollRight');
            }
        })
        
    }, [scrollPosition]);
    
    function onClickShow() {
        setOpen(!open);
    }

    function onClickAfegirCaracteristica() {
        navigate('caracteristica/'+props.masia?.id+'/nou');
    }

    function onClickAfegirEspai() {
        navigate('espai/'+props.masia.id+'/nou');
    }
	//const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';
	return (
		<Page title="El Pinatell - La Casa">
			<Box className='Casa-portada'>
                <div className="Home-idiomes">
					<Idioma selected={props.idioma} f_setSelected={props.f_setIdioma} idiomes={props.idiomes}/>
				</div>
                <div className={"Portada-line " + animation}></div>
				<div className="Casa-image">
					<img src={url+props.masia?.masia_image} className='Casa-img'/>
				</div>
				<div className="Casa-background"></div>
				<Box className={'Casa-content ' + title}>
                    <Typography variant='h2' className="Casa-title" color='#fff' textTransform='unset'>{props.masia?.masia_titol}</Typography>
                    <Edit endPoint='actualitzarMasia' initialValue={props.masia?.masia_titol} setInitialValue={props.setInitialValue} name={'masia_titol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Títol' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
				</Box>
                <div style={{position: 'absolute', top: '5%', left: '5%'}}>
                    <Edit endPoint='actualitzarMasia' initialValue={null} setInitialValue={props.setInitialValue} name={'masia_image'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'image'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
                </div>
			</Box>
            <Box className='Casa-section'>
                <Edit endPoint='actualitzarMasia' initialValue={props.masia?.masia_subtitol} setInitialValue={props.setInitialValue} name={'masia_subtitol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Subtítol' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
                <Typography variant='h1' className="Casa-title" textTransform='capitalize' color='#1D1D1B'>Masia El Pinatell</Typography>
                <div className="Casa-separator"></div>
                <Edit endPoint='actualitzarMasia' initialValue={props.masia?.descripcio} setInitialValue={props.setInitialValue} name={'descripcio'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Descripcio' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
                <Typography dangerouslySetInnerHTML={{__html: props.masia?.descripcio}} variant='body1' className='Casa-text' marginBottom='30px'></Typography>
                <div style={{display: 'flex', marginBottom: '20px'}}>
                    <Typography variant='h4' className="Casa-title" fontSize='24px !important' width='unset !important' textTransform='unset' color='#6F8A30 !important' fontWeight='800 !important'>{props.masia?.masia_caracteristiques}</Typography>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}} onClick={onClickShow}>{open ? <ExpandLessIcon htmlColor="#6F8A30" sx={{cursor: 'pointer'}}/> : <ExpandMoreIcon htmlColor="#6F8A30" sx={{cursor: 'pointer'}}/>}</div>
                </div>
                <ButtonAdmin text={'Afegir Característica'} icon={<AddIcon />} style={{marginBottom: '20px'}} action={onClickAfegirCaracteristica} setName={props.setName} setEndPoint={props.setEndPoint} setId={props.setId} setFolder={props.setFolder}/>
                <CaracteristiquesEdit data={props.masia?.caracteristiques} opened={open} setOpenDeleteModal={props.setOpenDeleteModal} setName={props.setName} setEndPoint={props.setEndPoint} setId={props.setId} setFolder={props.setFolder}/>
                <Icon />
                <Typography dangerouslySetInnerHTML={{__html: props.masia?.masia_inclou}} variant='body1' className='Casa-text' marginTop='30px'></Typography>
                <Edit endPoint='actualitzarMasia' initialValue={props.masia?.masia_inclou} setInitialValue={props.setInitialValue} name={'masia_inclou'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
            </Box>
            <div style={{padding: '0.5rem 0rem 0.5rem 0.5rem', display: 'flex', justifyContent:'center', alignItems:'center', borderRadius: '0.4rem'}}>
                <ButtonAdmin text={'Afegir Espai'} icon={<AddIcon />} style={{marginBottom: '20px'}} action={onClickAfegirEspai}/>

            </div>
            {props.masia?.espais.map((espai, index) => {
                return (
                    <SectionEdit isLeft={index % 2 == 0 ? true : false} data={espai} setOpenModal={props.setOpenModal} setOpenDeleteModal={props.setOpenDeleteModal} setName={props.setName} setEndPoint={props.setEndPoint} setId={props.setId} setFolder={props.setFolder} setInitialValue={props.setInitialValue} setType={props.setType} setLabel={props.setLabel}/>
                )
            })}
            <ScrollToTop smooth/>
            <Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default CasaEdit;
