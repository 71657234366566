import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../../components/layouts/Page";
import { ReactComponent as Mail } from "../../../assets/mail.svg";
import { ReactComponent as Phone } from "../../../assets/phone.svg";
import { ReactComponent as Ubi } from "../../../assets/ubi.svg";
import '../../../css/views/public/Preus.css';
import image from '../../../assets/img/BG.png';
import Footer from "../../../layouts/public/Footer";
import Calendari from "../../../components/elements/Calendari";
import Edit from "../Edit";
import AddIcon from '@mui/icons-material/Add';
import ButtonAdmin from "../ButtonAdmin";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';

function PreusEdit(props) {
    
    const navigate = useNavigate();

	function onClickAfegirCondicio() {
        navigate('condicio/'+props.masia.id+'/nou');
    }
    function onClickAfegirCondicioItem(id) {
        navigate('condicio/'+id+'/item/nou');
    }

    function onClickEliminarCondicio() {

    }

    function onClickEliminarCondicioItem() {

    }

	return (
		<Page title="El Pinatell">
			<Box className='Preus-portada'>
				<Box className='Preus-padding' padding={props.isMobile ? '15% 0' : '10% 20.6% 5%'}>
                    <div style={{display: 'flex'}}>
					    <Typography variant="h2" className="Preus-title">{props.masia?.reserves_titol}</Typography>
                        <Edit endPoint='actualitzarMasia' initialValue={props.masia?.reserves_titol} setInitialValue={props.setInitialValue} name={'reserves_titol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
                    </div>
					<div className="Preus-separator"></div>
                    <Edit endPoint='actualitzarMasia' initialValue={props.masia?.reserves_text} setInitialValue={props.setInitialValue} name={'reserves_text'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>

					<Box className="Preus-content">
						<Typography dangerouslySetInnerHTML={{__html: props.masia?.reserves_text}} variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}></Typography>
						
					</Box>
				</Box>
                <Box className='Preus-white'>
                    <Box className='Preus-padding'>
                        <Box className='Preus-flex'>
                            <Box className='Preus-reserves'>
                                <Typography variant="h3" className="Preus-subtitle" marginBottom='30px'>Reserves</Typography>
                                <Calendari />
                            </Box>
                        </Box>
                    </Box>
                    <Box className='Preus-padding' sx={{maxWidth: props.isMobile ? '100%': 'unset'}}>
                        <Box className='Preus-blue'>

                            <div style={{display: 'flex'}}>
                                <Typography variant="h3" className="Preus-subtitle" color='#fff !important' marginBottom='30px'>{props.masia?.reserves_subtitol}</Typography>
                                <Edit endPoint='actualitzarMasia' initialValue={props.masia?.reserves_subtitol} setInitialValue={props.setInitialValue} name={'reserves_subtitol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
                            </div>
                            <Edit endPoint='actualitzarMasia' initialValue={props.masia?.reserves_subtext} setInitialValue={props.setInitialValue} name={'reserves_subtext'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
                            <Typography dangerouslySetInnerHTML={{__html: props.masia?.reserves_subtext}} variant="body1" className="Preus-text" color='#fff !important' marginBottom='10px'></Typography>
                            <div className="Preus-line white"></div>
                            <Box className='Preus-flex'>
                                <Box className='Preus-llistat'>
                                    <Typography variant="h5" className="Preus-text" color='#fff'>Preus per a caps de setmana</Typography>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '88%'}}>
                                        <div>
                                            <div className="Preus-line white"></div>
                                            <div className="Preus-flex" style={{flexDirection: 'unset'}}>
                                                <Typography variant="body1" className="Preus-text" color='#fff'>2 nits, des de 1740€</Typography>
                                            </div>                                  
                                            <div className="Preus-line white"></div>
                                            <div className="Preus-flex" style={{flexDirection: 'unset'}}>
                                                <Typography variant="body1" className="Preus-text" color='#fff'>Ponts i festius, preus a consultar</Typography>
                                            </div>                                  
                                            <div className="Preus-line white"></div>
                                        </div>
                                    </div>
                                </Box>
                                <Box className='Preus-llistat'>
                                    <Typography variant="h5" className="Preus-text" color='#fff'>Preus per a setmana complerta</Typography>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '88%'}}>
                                        <div>
                                            <div className="Preus-line white"></div>
                                            <div className="Preus-flex" style={{flexDirection: 'unset'}}>
                                                <Typography variant="body1" className="Preus-text" color='#fff'>Preu a consultar</Typography>
                                            </div>                                    
                                            <div className="Preus-line white"></div>
                                        <Typography variant="body1" className="Preus-text" color='#fff'>Els preus inclouen IVA</Typography>
                                        <Typography variant="body1" className="Preus-text" color='#fff'>La taxa turística no està inclosa en el preu</Typography>
                                        </div>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className='Preus-padding'>
                        <Box className='Preus-condicions'>
                            
                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px'>Condicions de les reserves</Typography>
                            <div style={{padding: '0.5rem 0rem 0.5rem 0.5rem', display: 'flex', justifyContent:'start', alignItems:'center', borderRadius: '0.4rem'}}>
                                <ButtonAdmin text={'Afegir Condicio'} icon={<AddIcon />} style={{marginBottom: '20px'}} action={onClickAfegirCondicio}/>
                            </div>
                            {props.masia?.condicions.map((condicio) => {
                                return (
                                    <div>
                                        <div style={{display: 'flex'}}>
                                            <Typography variant="h6" className="Preus-text" fontWeight='bold' color='#1e1e1e !important'>{condicio.titol}</Typography>
                                            <ButtonAdmin 
                                                text={''} 
                                                icon={<DeleteIcon />} 
                                                variant='' 
                                                action={() => {onClickEliminarCondicio(condicio.id)}} 
                                                setOpenDeleteModal={props.setOpenDeleteModal}
                                                endPoint={'eliminarCondicio'}
                                                setEndPoint={props.setEndPoint}
                                                id={condicio.id}
                                                setId={props.setId}
                                                folder={condicio.id}
                                                setFolder={props.setFolder}
                                            />
                                        </div>
                                        {condicio?.items.map((item) => {
                                            return (
                                                <div style={{display: 'flex'}}>
                                                    <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>{item.text}</Typography>
                                                    <ButtonAdmin 
                                                        text={''} 
                                                        icon={<DeleteIcon />} 
                                                        variant='' 
                                                        action={() => {onClickEliminarCondicioItem(item.id)}} 
                                                        setOpenDeleteModal={props.setOpenDeleteModal}
                                                        endPoint={'eliminarCondicioItem'}
                                                        setEndPoint={props.setEndPoint}
                                                        id={item.id}
                                                        setId={props.setId}
                                                        folder={item.id}
                                                        setFolder={props.setFolder}
                                                    />
                                                </div>
                                            )
                                        })}
                                        <div style={{padding: '0.5rem 0rem 0.5rem 0.5rem', display: 'flex', justifyContent:'start', alignItems:'center', borderRadius: '0.4rem'}}>
                                            <ButtonAdmin text={'Afegir línia'} icon={<AddIcon />} style={{marginBottom: '20px'}} action={()=>{onClickAfegirCondicioItem(condicio.id)}}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
			</Box>
            <Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default PreusEdit;