import { SvgIcon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import '../../../css/views/public/Caracteristiques.css';
import { useNavigate } from "react-router-dom";
import ButtonAdmin from "../ButtonAdmin";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const CaracteristiquesEdit = (props) => {

    const navigate = useNavigate();

    function onClickAfegirCaracteristica(id) {
        navigate('caracteristica/'+id+'/item/nou');
    }

    function onClickEliminarCaracteristica() {

    }
	
	return (
		<Box className={'Caracteristiques-container ' + (props.opened ? 'opened' : 'closed')}>
            {props.data?.map((caracteristica, index) => {
                return (
                    <div key={index}>
                        <div style={{display:'flex'}}>
                            <Typography variant="h6" className="Caracteristiques-title">{caracteristica?.titol}</Typography>
                            <ButtonAdmin 
                                text={''} 
                                icon={<DeleteIcon />} 
                                variant='' 
                                action={() => {onClickEliminarCaracteristica(caracteristica.id)}} setOpenDeleteModal={props.setOpenDeleteModal}
                                endPoint={'eliminarCaracteristica'}
                                setEndPoint={props.setEndPoint}
                                id={caracteristica.id}
                                setId={props.setId}
                                folder={caracteristica.id}
                                setFolder={props.setFolder}
                            />
                        </div>

                        {caracteristica?.items.map((item, index) => {
                            return (
                                <div key={index} className="Caracteristiques-content">
                                    {/* <FormatListBulletedIcon htmlColor="#6F8A30"/> */}
                                    {item?.icon != null ? <img src={item.icon} className='Caracteristiques-icon'/> : null} 
                                    <div style={{display:'flex'}}>
                                        <Typography variant="body1" className="Caracteristiques-text">{item?.text}</Typography>
                                        <ButtonAdmin 
                                            text={''} 
                                            icon={<DeleteIcon />} 
                                            variant='' 
                                            action={() => {onClickEliminarCaracteristica(caracteristica.id)}} 
                                            setOpenDeleteModal={props.setOpenDeleteModal}
                                            endPoint={'eliminarCaracteristicaItem'}
                                            setEndPoint={props.setEndPoint}
                                            id={item.id}
                                            setId={props.setId}
                                            folder={item.id}
                                            setFolder={props.setFolder}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        <ButtonAdmin text={'Afegir Línia'} icon={<AddIcon />} style={{marginBottom: '20px', marginLeft: '10px'}} action={() => {onClickAfegirCaracteristica(caracteristica?.id)}}/>
                    </div>
                )
            })}
        </Box>
	);
}

export default CaracteristiquesEdit;
