import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Preus.css';
import Footer from "../../layouts/public/Footer";
import Calendari from "../../components/elements/Calendari";

function Privacitat(props) {

	
	return (
		<Page title="El Pinatell">
			<Box className='Preus-portada'>
				<Box className='Preus-padding' padding={props.isMobile ? '15% 0' : '10% 20.6% 5%'}>
					<Typography variant="h2" className="Preus-title">Política de privacitat</Typography>
					<div className="Preus-separator"></div>
					<Box className="Preus-content">
						<Typography variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}>En BALLUS SA nos preocupamos por la privacidad y la transparencia. A continuación, le indicamos en detalle los tratamientos de datos personales que realizamos, así como toda la información relativa a los mismos.</Typography>
						
					</Box>
				</Box>
                <Box className='Preus-white'>
                    
                    <Box className='Preus-padding'>
                        <Box className='Preus-condicions'>
                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px'>¿Quién es el responsable del tratamiento de sus datos?</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>BALLUS SA</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>A08013906.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>PASSEIG LA PAU, S/N - 08600 - BERGA - BARCELONA</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>938210193</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>ballus@ballus.cat</Typography>
                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px' marginTop='30px'>¿Cuáles son sus derechos cuando nos facilita sus datos?</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Cualquier persona tiene derecho a obtener confirmación sobre si en BALLUS SA estamos tratando, o no, datos personales que les conciernan.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. Igualmente tiene derecho a la portabilidad de sus datos.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En este caso, BALLUS SA dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Podrá ejercitar materialmente sus derechos de la siguiente forma: dirigiéndose a ballus@ballus.cat o Passeig de la Pau, s/n - 08600 Berga, Bergueda - Barcelona..</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Cuando se realice el envío de comunicaciones comerciales utilizando como base jurídica el interés legítimo del responsable, el interesado podrá oponerse al tratamiento de sus datos con ese fin.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente a través de su sitio web: www.aepd.es.</Typography>
                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px'>Tratamiento de los datos de contactos</Typography>

                            <Typography variant="h6" className="Preus-text" fontWeight='bold' color='#1e1e1e !important'>¿Con qué finalidad tratamos sus datos personales?</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>En BALLUS SA tratamos la información que nos facilitan las personas interesadas con el fin de atender su solicitud y enviarle comunicaciones comerciales. En el caso de que no facilite sus datos personales, no podremos cumplir con las finalidades descritas. No se van a tomar decisiones automatizadas en base a los datos proporcionados.</Typography>
                            <Typography variant="h6" className="Preus-text" fontWeight='bold' color='#1e1e1e !important'>¿Por cuánto tiempo conservaremos sus datos?</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Los datos se conservarán mientras el interesado no solicite su supresión.</Typography>
                            <Typography variant="h6" className="Preus-text" fontWeight='bold' color='#1e1e1e !important'>¿Con qué finalidad tratamos sus datos personales?</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>En determinades circumstàncies i per motius relacionats amb la seva situació particular, els interessats podran oposar-se al tractament de les seves dades. En aquest cas, BALLÚS, SA deixarà de tractar les dades, llevat per motius legítims imperiosos, o l’exercici o la defensa de possibles reclamacions.</Typography>
                            <Typography variant="h6" className="Preus-text" fontWeight='bold' color='#1e1e1e !important'>¿Cuál es la legitimación para el tratamiento de sus datos?</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Le indicamos la base legal para el tratamiento de sus datos:</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"· Ejecución de un contrato: Gestión de potenciales clientes que se han interesado sobre nuestros productos y/o servicios."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"· Consentimiento del interesado: Enviar comunicaciones promocionales, inclusive por vía electrónica."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"· Interés legítimo del Responsable: Gestión de los datos de contactos profesionales."}</Typography>
                            <Typography variant="h6" className="Preus-text" fontWeight='bold' color='#1e1e1e !important'>¿A qué destinatarios se comunicarán sus datos?</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>No se cederán datos a terceros, salvo obligación legal..</Typography>
                            <Typography variant="h6" className="Preus-text" fontWeight='bold' color='#1e1e1e !important'>Transferencias de datos a terceros países</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>No están previstas transferencias de datos a terceros países.</Typography>
                            <Typography variant="h6" className="Preus-text" fontWeight='bold' color='#1e1e1e !important'>¿Cómo hemos obtenido sus datos?
</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Los datos personales que tratamos en BALLUS SA proceden de: El propio interesado.</Typography>
                            </Box>
                    </Box>
                </Box>
			</Box>
            <Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default Privacitat;