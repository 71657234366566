import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../components/layouts/Page";
import { ReactComponent as Mail } from "../../assets/mail.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import { ReactComponent as Ubi } from "../../assets/ubi.svg";
import '../../css/views/public/Disponibilitat.css';
import image from '../../assets/img/BG.png';
import Contacte from "./Contacte";
import Footer from "../../layouts/public/Footer";

function Disponibilitat(props) {

	
	return (
		<Page title="El Pinatell">
			<div style={{backgroundColor: '#152525'}}>
				<Box className='Disponibilitat-portada'>
					<Box className='Disponibilitat-container'>
						<Typography variant="h2" className="Disponibilitat-title">{props.masia?.contacte_titol}</Typography>
						<div className="Disponibilitat-separator"></div>
						<Box className="Disponibilitat-content">
							<Typography dangerouslySetInnerHTML={{__html: props.masia?.contacte_text}} variant="body1" className="Disponibilitat-text" maxWidth={props.isMobile ? '100%' : "50%"}></Typography>
							<Box className="Disponibilitat-icons">
								<Box className="Disponibilitat-icon">
									<Mail />
									<Typography variant="body1" className="Disponibilitat-text" marginLeft='10px'>{props.masia?.contacte_mail}</Typography>
								</Box>
								<Box className="Disponibilitat-icon">
									<Phone />
									<Typography variant="body1" className="Disponibilitat-text" marginLeft='10px'>{props.masia?.contacte_telefon}</Typography>
								</Box>
								<Box className="Disponibilitat-icon">
									<Ubi />
									<Typography variant="body1" className="Disponibilitat-text" marginLeft='10px'>{props.masia?.contacte_ubicacio}</Typography>
								</Box>
							</Box>
						</Box>
						<div className="Disponibilitat-separator"></div>
					</Box>
				</Box>
				<Contacte />
			</div>
			<Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default Disponibilitat;