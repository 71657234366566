import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Manresa = (props) => {
    const { SvgIcon, subSvgIcon, button, extraClass, onClickIcon, icon } = props;
    return (
        <svg className={extraClass} onClick={()=>{onClickIcon(icon)}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.86 114.91">
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Tect">
                        <rect class="icon-map-color" x="133.98" y="83.97" width="26.66" height="26.66" rx="3" transform="translate(111.95 -75.66) rotate(45)"/>
                        <path class="icon-map-color" d="M1.74,0C3.06,0,8.35-.33,11.08,4.3L31,31.92,49.78,2.24A3.94,3.94,0,0,1,53.17,0h1.57V49.87c0,3.55.33,7.44,2.07,7.44v.58c-3.15,0-9.1-1.08-9.1-7.45V9.6L29.19,38.7H26.71L5.87,8.19l.66,49.7H3.22L3.89,5.21l-.5-.66C2.4,2.82,1.57.75,0,.67V0Z"/>
                        <path class="icon-map-color" d="M69.62,49.12c0-9.18,13.48-11.91,20.67-13.64V30.6c0-6-1.24-11.16-6.45-11.16A11.26,11.26,0,0,0,73.1,26.63l-.58-.41a11.9,11.9,0,0,1,11.57-7.61c6.29,0,12.41,3.89,12.41,11.74V49.87c0,3.55.33,7.44,2.06,7.44v.58c-2.81,0-7.19-.83-8.1-5.54C88.31,55.41,85.33,58.3,80,58.3,73.34,58.3,69.62,53.92,69.62,49.12Zm13.07,7.61c6,0,7.6-8,7.6-13.56V36.3C83.84,38.62,76.07,40.85,76.07,49,76.07,54.08,79.05,56.73,82.69,56.73Z"/>
                        <path class="icon-map-color" d="M111,19.6V19c2.89,0,7.52.91,8.18,6.2,3.72-3.8,7.36-6.61,12.32-6.61,6.95,0,10.5,3.56,10.5,8.76v22.5c0,3.55.33,7.44,2.07,7.44v.58c-3.14,0-8.27-1.08-8.27-7.45V28c0-5.71-2.89-8.19-5.79-8.19-7,0-10.75,10-10.75,16.21V49.87c0,3.55.33,7.44,2.07,7.44v.58c-3.14,0-8.27-1.08-8.27-7.45V27C113,23.49,112.7,19.6,111,19.6Z"/>
                        <path class="icon-map-color" d="M156.44,19.6V19c2.9,0,7.36.91,8.19,5.87,2.4-5.21,9-9,17-4l-.25.57c-9.84-4.3-16.7,4.88-16.7,14.56V49.87c0,3.55.33,7.44,2.07,7.44v.58c-3.14,0-8.27-1.08-8.27-7.45V27C158.51,23.49,158.18,19.6,156.44,19.6Z"/>
                        <path class="icon-map-color" d="M205.89,18.61c9.59,0,14,7.36,14,16.12H195.39c-.08.83-.08,1.66-.08,2.57,0,10.66,4.29,17,11.9,17,4.72,0,8.6-2.56,11.91-6.12l.5.42c-3.48,5.29-8.93,9.67-15.8,9.67-9.26,0-14.72-7.44-14.72-19.27S195.31,18.61,205.89,18.61Zm10.25,14.88c-.33-7.69-3.39-13.64-10.42-13.64-5.87,0-9.42,5.29-10.25,13.64Z"/>
                        <path class="icon-map-color" d="M229.12,51.11c2.65,3.55,7.44,5.54,12.24,5.54,5.54,0,9.26-2.65,9.26-7C250.62,38.45,230,43,230,29.94c0-6.37,5.21-11.33,13.15-11.33,5,0,8.68,1.9,11.9,5.87l-.57.41a13.56,13.56,0,0,0-10.51-4.63c-5.2,0-8.6,2.65-8.6,7,0,11.08,20.68,6.37,20.68,19.85,0,6.2-4.8,11.16-13,11.16-6,0-11.49-2.73-14.47-6.78Z"/>
                        <path class="icon-map-color" d="M265.92,49.12c0-9.18,13.48-11.91,20.67-13.64V30.6c0-6-1.24-11.16-6.45-11.16a11.27,11.27,0,0,0-10.75,7.19l-.58-.41a11.91,11.91,0,0,1,11.58-7.61c6.28,0,12.4,3.89,12.4,11.74V49.87c0,3.55.33,7.44,2.07,7.44v.58c-2.81,0-7.19-.83-8.1-5.54-2.15,3.06-5.13,5.95-10.51,5.95C269.64,58.3,265.92,53.92,265.92,49.12ZM279,56.73c6,0,7.61-8,7.61-13.56V36.3c-6.45,2.32-14.22,4.55-14.22,12.74C272.37,54.08,275.34,56.73,279,56.73Z"/>
                    </g>
                </g>
        </svg>
        
        
    );
};

export default Manresa;