import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/admin/GaleriaEdit.css';
import CloseIcon from '@mui/icons-material/Close';
import ImageUploading from 'react-images-uploading';

function ImatgeUpload(props) {
	
    const maxNumber = 69;

    const [images, setImages] = useState([]);

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        props.f_setImatges(imageList);
        setImages(imageList);
    };

	// const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';
    
    return (
        <div className="App">
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
                }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '40px'}}>
                        <button
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                            className='GaleriaEdit-image-button'
                            >
                            Sel·leccionar imatges
                        </button>
                    </div>
                    <Grid container spacing={2}>
                        {imageList.map((image, index) => (
                            <Grid item xs={6} md={4}>
                                <div key={index} className="image-item" style={{height: '250px', position: 'relative', boxShadow: '0px 0px 5px 0px #000000', borderRadius: '20px', overflow: 'hidden'}}>
                                    <img src={image['data_url']} alt="" width="100" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                    <div className="image-item__btn-wrapper">
                                    <div onClick={() => onImageRemove(index)} className='GaleriaEdit-delete'><CloseIcon htmlColor='#fff' sx={{width: '100%', height:'100%'}}/></div>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>

                    
                </div>
                )}
            </ImageUploading>
        </div>
    );
}

export default ImatgeUpload;