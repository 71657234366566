import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../components/layouts/Page";
import { ReactComponent as Icon } from "../../assets/logo_blanc.svg";
import '../../css/views/public/Home.css';
import '../../css/layouts/public/Menu.css';
import image1 from '../../assets/img/fotos/30.jpg';
import image2 from '../../assets/img/fotos/31.jpg';
import image3 from '../../assets/img/fotos/32.jpg';
import image4 from '../../assets/img/fotos/33.jpg';
import image5 from '../../assets/img/fotos/34.jpg';
import { useNavigate } from 'react-router';
import HomeButton from "../../components/elements/HomeButton";
import PinatellPortada from "../../components/elements/PinatellPortada";
import InstagramIcon from '@mui/icons-material/Instagram';
import GoogleIcon from '@mui/icons-material/Google';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Idioma from "../../components/elements/Idioma";



const Menu = (props) => {

	const [images, setImages] = useState([image1, image2, image3, image4, image5])
	const [animation, setAnimation] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (props.open) {
			setTimeout(() => {
				setAnimation(true);
			}, 1000);
			let elementsToShow = document.querySelectorAll(".show-on-open");
			setTimeout(() => {
				elementsToShow.forEach((element, index) => {
					element.classList.add('show-item-menu'+index);
				})
			}, 1000);
		} else {
			let elementsToShow = document.querySelectorAll(".show-on-open");
			setTimeout(() => {
				elementsToShow.forEach((element, index) => {
					element.classList.remove('show-item-menu'+index);
				})
			}, 1000);
			setAnimation(false);
		}
        
    }, [props.open]);

	useEffect(() => {
        let imatges = props.masia?.imatges;
		let aux = [];
		for (let i=0 ; i < imatges?.length ; i++) {
			if (imatges[i].tipus == 'portada') {
				aux.push(imatges[i])
			}
		}
		setImages(aux);
    }, [props.masia]);


	return (
		<Box className={"Menu-container " + (props.open ? 'menu-opened' : 'menu-closed')}>
			<Box className='Home-portada'>
				<div className="Home-image">
					{/* <img src={image} className='Home-img'/> */}
					<PinatellPortada images={images}/>
				</div>
				<div className="Home-background"></div>
				<Box className='Home-content'>
					{/* <Icon
						alt="Logo Pinatell"
						className='Home-logo'
						width={'100%'}
					/> */}
					<div className={"Home-separator " + (animation ? 'expand-separator' : 'not-expand-separator')}></div>
					<div className="Home-title">{props.masia?.menu_titol}</div>
					<div className="Home-buttons">
						<HomeButton text={props.masia?.menu_item1} f_clickMenu={props.f_clickMenu} link='la-masia' extraClass={'show-on-open'}/>
						<HomeButton text={props.masia?.menu_item2} f_clickMenu={props.f_clickMenu} link='entorn' extraClass={'show-on-open'}/>
						<HomeButton text={props.masia?.menu_item3} f_clickMenu={props.f_clickMenu} link='galeria' extraClass={'show-on-open'}/>
						<HomeButton text={props.masia?.menu_item4} f_clickMenu={props.f_clickMenu} link='com-arribar' extraClass={'show-on-open'}/>
						<HomeButton text={props.masia?.menu_item5} f_clickMenu={props.f_clickMenu} link='contacte' extraClass={'show-on-open'}/>
						<HomeButton text={props.masia?.menu_item6} f_clickMenu={props.f_clickMenu} link='reserves' extraClass={'show-on-open'}/>
						<HomeButton text={props.masia?.menu_item7} f_clickMenu={props.f_clickMenu} link='rutes' extraClass={'show-on-open'}/>
					</div>
					<div style={{marginTop: '10px', display: 'flex', justifyContent:'center'}}>
						<Typography variant="footer" className="Footer-text">
							<a href="https://www.instagram.com/masiaelpinatell/">
								<InstagramIcon />
							</a>
						</Typography>
						<Typography variant="footer" className="Footer-text" marginLeft={'10px'}>
							<a href="https://goo.gl/maps/x3xqwuWgusDJmcjx5">
								<GoogleIcon />
							</a>
						</Typography>
						<Typography variant="footer" className="Footer-text" marginLeft={'10px'}>
							<a href="mailto:info@masiaelpinatell.com">
								<MailOutlineIcon />
							</a>
						</Typography>
					</div>
					<div className="show-item-menu" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', transition: 'all 5s', opacity: (animation ? 1 : 0)}}>
						<Typography variant="footer" className='Footer-text' onClick={() => {navigate('/avis-legal')}}>
							Avís legal
						</Typography>
						<Typography variant="footer" marginLeft={'10px'} className='Footer-text' onClick={() => {navigate('/politica-privacitat')}}>
							Política de privacitat
						</Typography>
						<Typography variant="footer" marginLeft={'10px'} className='Footer-text' onClick={() => {navigate('/politica-cookies')}}>
							Política de cookies
						</Typography>
					</div>
				</Box>
				<div className="Home-idiomes">
					<Idioma selected={props.idioma} f_setSelected={props.f_setIdioma} idiomes={props.idiomes}/>
				</div>
				
			</Box>
		</Box>
	);
}

export default Menu;
