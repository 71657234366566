import { Box, Button, CircularProgress, Drawer, Fade, SnackbarContent, Stack, Typography } from "@mui/material";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";


export default function Cookies({ acceptCookies, setAcceptCookies, setHideMenu }) {
    const navigate = useNavigate();

    function onClickMoreInfo() {
        setAcceptCookies(true);
        navigate('/politica-cookies');
    }
	return (
		<Drawer
			anchor={"bottom"}
			open={!acceptCookies}
			sx={{ zIndex: 999999999 }}
			disableScrollLock={true}
			// onClose={toggleDrawer('bottom', false)}
		>
			<Box padding={"20px 30px"} sx={{ backgroundColor: "#323232" }}>
				<Typography variant="h4" color={"white"} mb={2}>
					Cookies
				</Typography>
				<Typography variant="body1" color={"white"} mb={2}>
                    Este sitio web únicamente utiliza cookies propias con fines técnicos. No recaba ni cede datos personales de los usuarios sin su conocimiento.
				</Typography>
				<Typography variant="body1" color={"white"} mb={2}>
                    Sin embargo, puede contener enlaces a sitios web de terceros con políticas de privacidad ajenas a esta página que usted podrá decidir si acepta o no cuando acceda a ellos.
				</Typography>
                <div style={{display: 'flex'}}>
                    <CustomButton title={'CERRAR'} onClick={() => setAcceptCookies(true)} />
				    <CustomButton title={'MÁS INFORMACIÓN'} onClick={() => onClickMoreInfo()} />
                </div>
			</Box>
		</Drawer>
	);
}
