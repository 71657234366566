import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import { useParams, useNavigate } from 'react-router';
import Title from "../../../components/layouts/Title"
import ImatgeUpload from "./ImatgeUpload";
import { create } from "../../../API/API.js";
import { useSnackbar } from 'notistack';
import { Typography, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function GestionarFotos(props) {

    const { id } = useParams();
    const [imatges, setImatges] = useState([]);
    const [imatgesAfegir, setImatgesAfegir] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        let imatges = props.masia?.imatges;
		let aux = [];
		for (let i=0 ; i < imatges?.length ; i++) {
			if (imatges[i].tipus == id) {
				aux.push(imatges[i])
			}
		}
		setImatges(aux);
    }, [props.masia]);

    const crearImatge = async (img) => {
        
        const res = {
            tipus: id,
            masia_id: props.masia?.id,
            imatge: img
        }

        const result = await create('crearImatge', res);
        enqueueSnackbar(result.message, {
            variant: 'success',
        });
    };

    function crearImatges() {
        imatgesAfegir.map((imatge) => {
            crearImatge(imatge.file);
        })
        props.setActualitzar(true);
        navigate(-1);
    }

    function imageRemove(image) {
        if (props.setOpenDeleteModal) {
            props.setId(image.id);
            props.setEndPoint('eliminarImatge');
            props.setOpenDeleteModal(true);
        }
    }

    // const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

	return (
		<Page title="El Pinatell">
            <div style={{padding: '10%'}}>
                <Title
                    title={'Gestionar ' + id}
                    subtitle={
                        'Gestiona les fotos de la masia'
                    }
                />

                <div className="GaleriaEdit-actuals">
                    <Typography variant="h5" fontFamily={'Quicksand'}>Imatges Actuals</Typography>
                    <Grid container spacing={2}>
                        {imatges.length > 0 ? imatges?.map((image, index) => (
                            <Grid item xs={6} md={4}>
                                <div key={index} className="image-item" style={{height: '250px', position: 'relative', boxShadow: '0px 0px 5px 0px #000000', borderRadius: '20px', overflow: 'hidden'}}>
                                    <img src={url+image.imatge} alt="" width="100" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                    <div className="image-item__btn-wrapper">
                                    <div onClick={() => imageRemove(image)} className='GaleriaEdit-delete'><CloseIcon htmlColor='#fff' sx={{width: '100%', height:'100%'}}/></div>
                                    </div>
                                </div>
                            </Grid>
                        )) : <Typography variant="body1" fontFamily={'Quicksand'} textAlign={'center'} width={'100%'} marginTop={'50px'}>No hi ha imatges actualment</Typography>}
                    </Grid>
                </div>

                <Typography variant="h5" fontFamily={'Quicksand'} marginTop={'50px'}>Afegir Imatges</Typography>
                <ImatgeUpload f_setImatges={setImatgesAfegir}/>
                <Box my={2}>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={crearImatges}
                    >
                        {'Afegir Imatges'}
                    </Button>
                </Box>
			</div>
		</Page>
	);
}

export default GestionarFotos;