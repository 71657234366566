import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { ReactComponent as Icon } from "../../assets/img/logo_inv.svg";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Container } from "@mui/system";
import PinatellIcon from "../../components/elements/PinatellIcon";
import InstagramIcon from '@mui/icons-material/Instagram';
import GoogleIcon from '@mui/icons-material/Google';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useNavigate } from 'react-router';

const Footer = (props) => {
    let navigate = useNavigate();
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Container>
				<Grid container spacing={3} className={classes.main}>
					<Grid item md={4} sx={{display: 'flex', alignItems: 'flex-end', flexDirection:'column'}}>
						<div style={{marginTop: '10px', display: 'flex'}}>
							<Typography variant="footer" className="Footer-text">
								<a href="https://www.instagram.com/masiaelpinatell/">
									<InstagramIcon />
								</a>
							</Typography>
							<Typography variant="footer" className="Footer-text" marginLeft={'10px'}>
								<a href="https://goo.gl/maps/x3xqwuWgusDJmcjx5">
									<GoogleIcon />
								</a>
							</Typography>
							<Typography variant="footer" className="Footer-text" marginLeft={'10px'}>
								<a href="mailto:info@masiaelpinatell.com">
									<MailOutlineIcon />
								</a>
							</Typography>
						</div>
					</Grid>
					<Grid item md={props.isMobile ? 12 : 4} sx={{width: (props.isMobile ? '100%': 'unset')}}>
						<div style={{ width:'100px', margin: '0 auto'}}>
							<PinatellIcon extraClass={"Menu-logo "}/>
						</div>
					</Grid>
					<Grid item md={4} sx={{display: 'flex', flexDirection: 'column'}}>
						<Typography variant="footer" className='Footer-text' onClick={() => {navigate('/avis-legal')}}>
							Avís legal
						</Typography>
						<Typography variant="footer" marginTop={'10px'} className='Footer-text' onClick={() => {navigate('/politica-privacitat')}}>
							Política de privacitat
						</Typography>
						<Typography variant="footer" marginTop={'10px'} className='Footer-text' onClick={() => {navigate('/politica-cookies')}}>
							Política de cookies
						</Typography>
						{/* <Box mt={2} display="flex" flexDirection={"column"}>
							<a
								href="/avis-legal"
								style={{
									textDecoration: "none",
									cursor: "pointer",
								}}
							>
								<Typography variant="footer">
									Avís Legal
								</Typography>
							</a>

							<a
								href="/politica-privacitat"
								style={{
									textDecoration: "none",
									cursor: "pointer",
								}}
							>
								<Typography variant="footer">
									Política de privacitat
								</Typography>
							</a>
						</Box> */}
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#152525',
		borderTop: '1px solid white'
	},
	main: {
		paddingTop: 50,
		paddingBottom: 50,
		display: "flex",
		alignItems: "center",
		justifyContent: 'center'
	},
	header: {
		textAlign: "center",
	},
	logo: {
		fill: 'white',
	},
	icon: {
		width: 60,
		height: 60,
		border: "1px solid " + theme.color.background,
		borderRadius: 50,
		padding: 10,
		color: theme.color.background,
	},
	iconSVG: {
		width: 25,
		height: 25,
		border: "1px solid " + theme.color.background,
		borderRadius: 50,
		padding: 10,
		fill: theme.color.background,
		fontSize: 20,
	},
	iconWrap: {
		"&.MuiButtonBase-root": {
			borderRadius: 50,
		},
	},
}));
