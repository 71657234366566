import React, { useEffect, useState } from "react";
import { SvgIcon, Typography } from "@mui/material";
import '../../../css/views/public/PinatellCarousel.css';
import { useNavigate } from 'react-router';
import StraightIcon from '@mui/icons-material/Straight';
import { ReactComponent as Fletxa } from '../../../assets/icon-fletxa.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';

const PinatellCarouselEdit = (props) => {

    const [selected, setSelected] = useState(0);
    const [pixels, setPixels] = useState(0);
    const [expand, setExpand] = useState(false);
    const [background, setBackground] = useState('');
    const [clear, setClear] = useState('');
    const imageSize = 230;
    const navigate = useNavigate()

    useEffect(() => {
		if (expand) {
            props.setHideMenu(true);
		}
        
    }, []);

    useEffect(() => {
		if (expand) {
            setBackground('clear-background');
                setClear('clear');

            props.setHideMenu(true);
		}
        else {
            setBackground('');
            setClear('');
        }
        
    }, [expand]);

    useEffect(() => {
		setSelected(0);
        setPixels(0);
        
    }, [props.title]);

    function onClickImage(index) {
        setSelected(index);
        setPixels(index * imageSize * (-1));
    }

    function onClickImageBig() {
        if (expand) {
            setExpand(false);
            props.setHideMenu(false);
        }
    }

    function onClickLeftBig() {
        if (selected <= 0) {
            setSelected(props.images.length-1)
        }
        else {
            setSelected(selected-1);
        }
    }

    function onClickRightBig(index) {
        if (selected >= props.images.length-1) {
            setSelected(0)
        }
        else {
            setSelected(selected+1);
        }
    }

    function onClickLeft() {
        setPixels(pixels - (imageSize * (-1)));
    }

    function onClickRight() {
        setPixels(pixels + (imageSize * (-1)));
    }

    function onClickTitle(title) {
        props.f_setTitle(title);

    }

    const gestionarFotos = (titol) => {
        return (
            <div onClick={() => {navigate(titol)}} style={{border: '1px solid #fff', color: '#fff', marginLeft: '5%', cursor: 'pointer', padding: '5px 10px', marginTop: titol == 'exterior' ? '30px' : '0'}}>
                <Typography variant='h5' color='#fff' width='212px' cursor='pointer' fontFamily='Quicksand'>Gestionar {titol}</Typography>
            </div>
        )
    }

    function renderTitles() {
        return (
            <div className={"PinatellCarousel-titles " + clear}>
                <div style={{display: 'flex'}}>
                    <div className="PinatellCarousel-title" onClick={()=>{onClickTitle('interior')}}>
                        <div className={"PinatellCarousel-title-line " + (props.title == 'interior' ? 'title-selected' : '')}></div>
                        <Typography variant={(props.isMobile ? 'h5' : 'h2')} color={props.title == 'interior' ? 'white' : '#ffffff75'} fontFamily='DM Serif Display' textTransform='lowercase'>Interior</Typography>
                        
                    </div>
                    {props.title == 'interior' ? gestionarFotos('interior') : null}
                </div>
                <div style={{display: 'flex'}}>
                    <div className="PinatellCarousel-title" onClick={()=>{onClickTitle('exterior')}} style={{marginTop: (props.isMobile ? '10px' : '30px')}}>
                        <div className={"PinatellCarousel-title-line " + (props.title == 'exterior' ? 'title-selected' : '')}></div>
                        <Typography variant={(props.isMobile ? 'h5' : 'h2')} color={props.title == 'exterior' ? 'white' : '#ffffff75'}  fontFamily='DM Serif Display' textTransform='lowercase'>Exterior</Typography>
                        
                    </div>
                    {props.title == 'exterior' ? gestionarFotos('exterior') : null}
                </div>
            </div>
        )
    }


	// const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

	return (
		<div className={"PinatellCarousel-container"}>
            <div className={"PinatellCarousel-content "}>
                <div className="PinatellCarousel-carousel">
                    {props.imatges?.map((image, index) => {
                        if (image.tipus == props.title) {
                            return (
                                <div key={index} className={"PinatellCarousel-image " + (selected == index ? 'big-selected ' : ' ') + (expand ? 'image-big' : '')} >
                                    <img src={url+image.imatge} className={'PinatellCarousel-img ' + (expand ? 'image-auto' : '')}/>
                                </div>
                            )
                        }
                    })}
                </div>
                <div className={"PinatellCarousel-background " + background}></div>
            
            </div>
            {renderTitles()}
            <div className={"MiniCarousel-container " + clear}>
                <div className="MiniCarousel-arrows">
                        <StraightIcon htmlColor="#fff" className='MiniCarousel-arrow-left' onClick={() => {onClickLeft()}}/>
                        <StraightIcon htmlColor="#fff" className='MiniCarousel-arrow-right' onClick={() => {onClickRight()}}/>
                </div>
                <div className="MiniCarousel-overflow">
                    <div className="MiniCarousel-carousel" style={{transform: 'translateX('+pixels+'px)', transition: 'transform 0.5s ease-in-out'}}>
                        {props.imatges?.map((image, index) => {
                            if (image.tipus == props.title) {
                                return (
                                    <div key={index} className="MiniCarousel-image">
                                        <img src={url+image.imatge} className={'MiniCarousel-img '}/>
                                        <div className={"MiniCarousel-background " + (selected == index ? 'selected' : 'not-selected')} onClick={() => {onClickImage(index)}}>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
		</div>
	)
}

export default PinatellCarouselEdit;