import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Page from "../../components/layouts/Page";
import { ReactComponent as Icon } from "../../assets/logo_blanc.svg";
import '../../css/views/public/Entorn.css';
import image from '../../assets/img/entorn.jpg';
import roures from '../../assets/img/roures.jpeg';
import estela from '../../assets/img/estela.jpg';
import roc from '../../assets/img/roc.jpg';
import rasos from '../../assets/img/rasos.jpeg';
import figuerassa from '../../assets/img/figuerassa.webp';
import berga from '../../assets/img/berga.jpeg';
import queralt from '../../assets/img/queralt.jpeg';
import bici from '../../assets/img/bici.jpg';
import Footer from "../../layouts/public/Footer";

import useScrollPosition from "../../components/elements/useScrollPosition";
import ScrollToTop from "react-scroll-to-top";
import Idioma from "../../components/elements/Idioma";
import Section from "./Section";
import Place from "./Place";
import PinatellIcon from "../../components/elements/PinatellIcon";

function Entorn(props) {
	

	const Background = (props) => {
		const [title, setTitle] = useState("L'Entorn");

	const [animation, setAnimation] = useState("");
	const scrollPosition = useScrollPosition();
	const [line, setLine] = useState('');
    const [titleAni, setTitleAni] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
		setTimeout(() => {
			setLine('Portada-expand');
		}, 500);
        setTimeout(() => {

            setTitleAni('title-expand');
		}, 500);
        
    }, []);

	useEffect(() => {
		let height = window.innerHeight;

		let elementsToShowLeft = document.querySelectorAll(".show-on-scroll-left");
        elementsToShowLeft.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollLeft');
            }
        })
        let elementsToShowRight = document.querySelectorAll(".show-on-scroll-right");
        elementsToShowRight.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollRight');
            }
        })
        
    }, [scrollPosition]);

    // const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

		return (
			<Box>
				<div className="Entorn-fixed">
					<Typography variant='h2' className={"Entorn-title-fixed " + animation} maxWidth={'100%'} textAlign='center' textTransform='capitalize' color='#1D1D1B' fontWeight='800'><PinatellIcon extraClass='Pinatell-black'/></Typography>
				</div>
				<div style={{marginBottom: '116px'}}>
					<Box className="Entorn-portada">
						<div className="Home-idiomes">
							<Idioma selected={props.idioma} f_setSelected={props.f_setIdioma} idiomes={props.idiomes}/>
						</div>
						<div className="Entorn-image">
							<img src={url+props.masia?.entorn_image} className='Entorn-img'/>
						</div>
						<div className="Entorn-background"></div>
						<Box className={'Entorn-content ' + titleAni}>
							<Typography variant='h1' className="Entorn-title" color='#fff' textTransform='unset'>{props.masia?.entorn_titol}</Typography>
							<Typography variant='h2' className='Entorn-text' color='#fff !important' marginTop='30px' textTransform='unset'></Typography>
						</Box>
						<div className={"Portada-line " + line}></div>
					</Box>
					{props.masia?.entorns.map((entorn, index) => {
						return (
							<Place isLeft={index % 2 == 0 ? true : false} data={entorn} />
						)
					})}
				</div>
			</Box>
		)
	}

	
	return (
		<Page title="El Pinatell">
			<Background masia={props.masia} isMobile={props.isMobile} idioma={props.idioma} f_setIdioma={props.f_setIdioma} idiomes={props.idiomes}/>

            <ScrollToTop smooth/>
			<Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default Entorn;