import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import CasaIcon from '../../views/public/Mapa/CasaIcon';
import '../../css/views/public/Arribar.css';

const MapContainer = ({ text }) => (
    <div>
        {text}
    </div>
);

const exampleMapStyles = [
  {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
          {
              color: "#eeeeee",
          },
      ],
  },
  {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
          {
              visibility: "off",
          },
      ],
  },
  {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
          {
              color: "#9e9e9e",
          },
      ],
  },
];
 
class GoogleMaps extends Component {
  static defaultProps = {
    center: {
      lat: 42.11505085020447,
      lng:  1.800434456132054
    },
    zoom: 15
  };

  
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '300px', width: '100%', marginTop: "46px"}}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyAV1zRuF4bsl5gBe42wDAthasx0VJBx-C0" }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            options={{
                styles: exampleMapStyles,
                disableDefaultUI: true
                
            }}
        >
          <MapContainer
            lat={42.115329987765946}
            lng={1.8005044550816627}
            text={<CasaIcon extraClass={'Arribar-icon-maps'}/>}
          />
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default GoogleMaps;