import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../../components/layouts/Page";
import { ReactComponent as Mail } from "../../../assets/mail.svg";
import { ReactComponent as Phone } from "../../../assets/phone.svg";
import { ReactComponent as Ubi } from "../../../assets/ubi.svg";
import '../../../css/views/public/Disponibilitat.css';
import image from '../../../assets/img/BG.png';
import Contacte from "../../public/Contacte";
import Footer from "../../../layouts/public/Footer";
import Edit from "../Edit";

function DisponibilitatEdit(props) {

	
	return (
		<Page title="El Pinatell">
			<div style={{backgroundColor: '#152525'}}>
				<Box className='Disponibilitat-portada'>
					<Box className='Disponibilitat-container'>
						<div style={{display: 'flex'}}>
							<Typography variant="h2" className="Disponibilitat-title">Consulta la disponibilitat</Typography>
							<Edit endPoint='actualitzarMasia' initialValue={props.masia?.contacte_titol} setInitialValue={props.setInitialValue} name={'contacte_titol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						</div>
						<div className="Disponibilitat-separator"></div>
						<Edit endPoint='actualitzarMasia' initialValue={props.masia?.contacte_text} setInitialValue={props.setInitialValue} name={'contacte_text'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						<Box className="Disponibilitat-content">
							<Typography dangerouslySetInnerHTML={{__html: props.masia?.contacte_text}} variant="body1" className="Disponibilitat-text" maxWidth={props.isMobile ? '100%' : "50%"}></Typography>
							<Box className="Disponibilitat-icons">
								<Box className="Disponibilitat-icon">
									<Mail />
									<Typography variant="body1" className="Disponibilitat-text" marginLeft='10px'>{props.masia?.contacte_mail}</Typography>
									<Edit endPoint='actualitzarMasia' initialValue={props.masia?.contacte_mail} setInitialValue={props.setInitialValue} name={'contacte_mail'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
								</Box>
								<Box className="Disponibilitat-icon">
									<Phone />
									<Typography variant="body1" className="Disponibilitat-text" marginLeft='10px'>{props.masia?.contacte_telefon}</Typography>
									<Edit endPoint='actualitzarMasia' initialValue={props.masia?.contacte_telefon} setInitialValue={props.setInitialValue} name={'contacte_telefon'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
								</Box>
								<Box className="Disponibilitat-icon">
									<Ubi />
									<Typography variant="body1" className="Disponibilitat-text" marginLeft='10px'>{props.masia?.contacte_ubicacio}</Typography>
									<Edit endPoint='actualitzarMasia' initialValue={props.masia?.contacte_ubicacio} setInitialValue={props.setInitialValue} name={'contacte_ubicacio'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
								</Box>
							</Box>
						</Box>
						<div className="Disponibilitat-separator"></div>
					</Box>
				</Box>
				<Contacte />
			</div>
			<Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default DisponibilitatEdit;