import { SvgIcon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import '../../css/views/public/Caracteristiques.css';
import Rombo from "./Mapa/Rombo";

const Caracteristiques = (props) => {

    
	
	return (
		<Box className={'Caracteristiques-container ' + (props.opened ? 'opened' : 'closed')}>
            {props.data?.map((caracteristica, index) => {
                return (
                    <div key={index}>
                        <Typography variant="h6" className="Caracteristiques-title">{caracteristica?.titol}</Typography>
                        {caracteristica?.items.map((item, index) => {
                            return (
                                <div key={index} className="Caracteristiques-content">
                                    {/* <FormatListBulletedIcon htmlColor="#6F8A30"/> */}
                                    {item?.icon != null ? <img src={item.icon} className='Caracteristiques-icon'/> : null} 
                                    <Rombo extraClass={'Caracteristiques-rombo '}/> 
                                    <Typography variant="body1" className="Caracteristiques-text">{item?.text}</Typography>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </Box>
	);
}

export default Caracteristiques;
