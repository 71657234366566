import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Rombo = (props) => {
    const { SvgIcon, subSvgIcon, button, extraClass } = props;
    return (
            <svg className={extraClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.21 35.21"><g id="Capa_2" data-name="Capa 2"><g id="Tect"><rect class="icon-map-color" x="4.28" y="4.28" width="26.66" height="26.66" rx="3" transform="translate(17.61 -7.29) rotate(45)"/></g></g></svg>
        
    );
};

export default Rombo;