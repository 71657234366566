import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    Button
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { create, get } from '../../../API/API';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import Page from '../../../components/layouts/Page';
import Title from '../../../components/layouts/Title';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        padding: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    title: {
        padding: 20
    },
    wrap: {
        padding: 40,
        borderRadius: 5,
        boxShadow:
            '-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
    },
}));

const AddCaracteristica = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const crearCaracteristica = async (values) => {
        const data = {
            titol: values.titol,
            masia_id: id
        }
        const result = await create('crearCaracteristica', data);
        enqueueSnackbar(result.message, {
            variant: 'success',
        });
        props.setActualitzar(true);
        navigate(-1, { state: values });
    };

    return (
        <Page title="Add Caracteristica">
            <Formik
                initialValues={{
                    titol: ''
                }}
                // validationSchema={Yup.object().shape({
                //     name: Yup.string().required('El nom és obligatòri')
                // })}
                onSubmit={(values) => {
                    crearCaracteristica(values);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={{padding: '0 40px'}}>
                            <Title
                                title={'Afegir Caracteristica'}
                                subtitle={
                                    'Entra les dades per guardar una nova entrada'
                                }
                            />
                        </div>
                        <Box className={classes.wrap}>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(
                                            touched.titol && errors.titol
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.titol && errors.titol
                                        }
                                        label="Titol"
                                        margin="normal"
                                        name="titol"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.titol}
                                    />
                                </Grid>
                            </Grid>

                            <Box my={2} sx={{maxWidth: '50%', paddingTop: '40px', margin: '0 auto'}}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {'crear'}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </Page>
    );
};

export default AddCaracteristica;
