import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../components/layouts/Page";
import { ReactComponent as Icon } from "../../assets/logo_blanc.svg";
import '../../css/views/public/Home.css';
import image from '../../assets/img/BG.png';

const HomeButton = (props) => {
	return (
		<div className="HomeButton-container">
			<div className="HomeButton-text">{props.text}</div>
		</div>
	)
}

function Home() {

	
	return (
		<Page title="El Pinatell">
			<Box className='Home-portada'>
				
			</Box>
		</Page>
	);
}

export default Home;
