import axios from "axios";
import moment from "moment";

//const url = "http://127.0.0.1:8000/api/";
const url = "https://api.masiaelpinatell.com/public/api/";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
			remember_me: true,
		})
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const getUser = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let data;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "user", config)
		.then((response) => {
			if (response.status === 200) {
				message = response.status;
				data = response.data;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
			}
		});
	return { message, data };
};

const logout = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let get_user;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "logout", config)
		.then((response) => {
			if (response.status === 200) {
				get_user = response.data;
				message = "400";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return { get_user, message };
};

const getElements = async (element) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElement = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const updateElement = async (element, key, values) => {

	const user = JSON.parse(localStorage.getItem("user"));
	let result = {
        message: '',
        data: null,
    };
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	var form_data = new FormData();

    for (var i in values) {
        form_data.append(i, values[i]);
    }
	await axios
		.post(url + element + "/" + key, form_data, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				result.message = response.data.message;
				result.data = response.data.data;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				result.message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return result;
};

const create = async (element, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let success = false;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
    var form_data = new FormData();

    for (var key in values) {
        form_data.append(key, values[key]);
    }
	await axios
		.post(url + element, form_data, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				success = response.data.success;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message, success };
};

const deleteElement = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.delete(url + element + "/" + key, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const sendEmail = async (element, values) => {
    let message;
	let success = false;
    var form_data = new FormData();

    for (var key in values) {
        form_data.append(key, values[key]);
    }
	await axios
	.post(url + element, form_data)
	.then((response) => {
		if (response.status === 200) {
			message = response.data.message;
			success = response.data.success;
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = response.data.message;
		}
	})
	.catch((error) => {
		console.log(error);
	});

    return message;
};

const calendaris = async (calendari, api_key) => {
    let calendaris;
    let url_cal = `https://www.googleapis.com/calendar/v3/calendars/${calendari}/events?key=${api_key}&timeMin=${new Date().toISOString()}`;
    await axios
        .get(url_cal)
        .then((response) => {
            calendaris = response.data.items;
        })
        .catch((error) => {});
    return calendaris;
};


export {
	Login,
	getUser,
	logout,
	getElements,
	getElement,
	updateElement,
	create,
	deleteElement,
	sendEmail,
	calendaris
};
