import React, { useEffect, useState } from 'react';
import { Typography, Box, Tooltip, IconButton, Grid, TextField, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import RichText from './RichText';
import { deleteElement, updateElement } from '../../API/API';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
        width: '100%'
    },
    wrap: {
        padding: 20,
        borderRadius: 5,
        backgroundColor: 'white',
        boxShadow:
            '-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
    },
}));

const DeleteModal = (props) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState(null);

    const { name } = props;

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#fff',
        boxShadow: 24,
        width: '40%',
        height: 'auto%',
        overflow: 'scroll'
    };

    const eliminar = async (values) => {
        
        const res = {
            name: props.name,
            value: data != null ? data : values.content,
            id: props.id,
            folder: props.folder
        }
        
        const result = await deleteElement(props.endPoint, props.id, res);
        enqueueSnackbar(result.message, {
            variant: 'success',
        });
        props.setOpen(false);
        setData(null);
        if (props.setActualitzar)  {
            props.setActualitzar(true);
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={props.handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open}>
                <Box sx={styleModal} className="EditModal-modal">
                    <Formik
                        initialValues={{}}
                        onSubmit={(values) => {
                            eliminar(values);
                        }}
                        
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit} style={{width: '100%'}}>
                                <Box className={classes.wrap}>
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <div>
                                                <Typography variant='h4' fontFamily={'Quicksand'} textAlign={'center'}>Estàs segur que vols eliminar-lo?</Typography>
                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    <div style={{border: '1px solid #152525', borderRadius: '10px', padding: '1%', margin: '10px', background: '#152525', color: 'white', cursor: 'pointer'}} onClick={eliminar}>Eliminar</div>
                                                    <div style={{border: '1px solid #152525', borderRadius: '10px', padding: '1%', margin: '10px', background: '#152525', color: 'white', cursor: 'pointer'}} onClick={()=>{props.setOpen(false)}}>Cancel·lar</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Fade>
        </Modal>
    );

    
};

DeleteModal.defaultProps = {
    type: 'text',
    id: '',
    label: ''

}

export default DeleteModal;







