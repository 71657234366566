import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Preus.css';
import Footer from "../../layouts/public/Footer";
import Calendari from "../../components/elements/Calendari";

function PoliticaCookies(props) {

	
	return (
		<Page title="El Pinatell">
			<Box className='Preus-portada'>
				<Box className='Preus-padding' padding={props.isMobile ? '15% 0' : '10% 20.6% 5%'}>
					<Typography variant="h2" className="Preus-title">Política de Cookies</Typography>
					{/* <div className="Preus-separator"></div> */}
					<Box className="Preus-content" sx={{display: 'block'}}>
						{/* <Typography variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}>Ballús, SA manté aquest lloc web amb l’objectiu de facilitar l’accés a informació corporativa i no corporativa actualitzada i exacta. Tractarem de corregir els errors que se’ns assenyali amb la màxima celeritat possible. No obstant, Ballús, SA no pot garantir la inexistència d’errors ni que el contingut de la informació es trobi permanentment actualitzat.</Typography>
                        <Typography variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}>En conformitat a l’establert a les lleis 15/1999 (Protecció de Dades de Caràcter Personal) i 34/2002 (Serveis de la Societat de la Informació i del Comerç Electrònic) s’informa del següent:</Typography>
						 */}
					</Box>
				</Box>
                <Box className='Preus-white'>
                    
                    <Box className='Preus-padding'>
                        <Box className='Preus-condicions'>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>A través de este sitio web no se recaban datos personales de los usuarios sin su conocimiento, ni se ceden a terceros..</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Esta web no utiliza cookies para recoger información de los usuarios, ni registra las direcciones IP de acceso. Únicamente se utilizan cookies propias, de sesión, con finalidad técnica (aquellas que permiten al usuario la navegación a través del sitio web y la utilización de las diferentes opciones y servicios que en ella existen).</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Este sitio contiene enlaces a otros sitios web de terceros, cuyas políticas de privacidad son ajenas a la de este sitio web. Al acceder a tales sitios web usted puede decidir si acepta sus políticas de privacidad y de cookies. Con carácter general, si navega por Internet usted puede aceptar o rechazar las cookies de terceros desde las opciones de configuración de su navegador.</Typography>

                        </Box>
                    </Box>
                </Box>
			</Box>
            <Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default PoliticaCookies;