import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { calendaris } from "../../API/API";
import caLocale from '@fullcalendar/core/locales/ca'

function FullCalendari(props) {
    const [calendari, setCalendari] = useState([]);

    useEffect(() => {
        const getCalendari = async () => {
			const result = await calendaris("masiaelpinatell@gmail.com", "AIzaSyBIOqUnwC_gy7poH_wOgsDrrjG7UCnCoX8");
            const data = result?.map((item) => {
                return { start: item?.start?.date, end: item?.end?.date };
            });
			setCalendari(data);
		};
		getCalendari();
	
        
    }, []);



    

    return (
        <FullCalendar
            plugins={[ dayGridPlugin ]}
            initialView="dayGridMonth"
            weekends={true}
            events={calendari}
            eventColor='#d50000'
            locale={caLocale}
        />
    )
	
}

export default FullCalendari;
