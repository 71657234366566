import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from 'react';
import Page from "../../components/layouts/Page";
import { ReactComponent as Mail } from "../../assets/mail.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import { ReactComponent as Ubi } from "../../assets/ubi.svg";
import '../../css/views/public/Arribar.css';
import image from '../../assets/img/BG.png';
import Contacte from "./Contacte";
import Footer from "../../layouts/public/Footer";
import GoogleMaps from "../../components/elements/GoogleMaps";
import MapaIcon from "../../components/elements/MapaIcon";
import Berga from "./Mapa/Berga";
import Manresa from "./Mapa/Manresa";
import Barcelona from "./Mapa/Barcelona";
import Girona from "./Mapa/Girona";
import Lleida from "./Mapa/Lleida";
import Tarragona from "./Mapa/Tarragona";
import Perpinya from "./Mapa/Perpinya";
import Toulouse from "./Mapa/Toulouse";
import Rombo from "./Mapa/Rombo";
import CasaIcon from "./Mapa/CasaIcon";

function Arribar(props) {

	const [iconClicked, setIconClicked]  = useState(null);
	
	return (
		<Page title="El Pinatell">
			<Box className='Preus-portada'>
				<Box className='Preus-padding' padding={props.isMobile ? '15% 0' : '10% 20.6% 5%'}>
					<Typography variant="h2" className="Preus-title">{props.masia?.arribar_titol}</Typography>
					<div className="Preus-separator"></div>
					<Box className="Preus-content">
						<Typography dangerouslySetInnerHTML={{__html: props.masia?.arribar_text}} variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '60%'}></Typography>
						
					</Box>
					<div className="Arribar-map">
						<div className="Arribar-line"></div>

						<div style={{display: (props.isMobile ? 'block' : 'flex'), alignItems: 'center'}}>
							<div className="Arribar-map-container" style={{position: 'relative', width: (props.isMobile ? '100%' : '75%')}}>
								<MapaIcon />
								<div style={{position: 'absolute', top: '30%', left: '50%'}}>
									<CasaIcon extraClass={'Arribar-icon-map ' + (iconClicked == 'Casa' ? 'icon-clicked' : '')} onClickIcon={setIconClicked} icon={'Casa'}/>
								</div>
								<div style={{position: 'absolute', top: '35%', left: '50%'}}>
									<Berga extraClass={'Arribar-icon-map ' + (iconClicked == 'Berga' ? 'icon-clicked' : '')} onClickIcon={setIconClicked} icon={'Berga'}/>
								</div>
								<div style={{position: 'absolute', top: '50%', left: '50%'}}>
									<Manresa extraClass={'Arribar-icon-map ' + (iconClicked == 'Manresa' ? 'icon-clicked' : '')} onClickIcon={setIconClicked} icon={'Manresa'}/>
								</div>
								<div style={{position: 'absolute', top: '63%', left: '50%'}}>
									<Barcelona extraClass={'Arribar-icon-map ' + (iconClicked == 'Barcelona' ? 'icon-clicked' : '')} onClickIcon={setIconClicked} icon={'Barcelona'}/>
								</div>
								<div style={{position: 'absolute', top: '45%', left: '25%'}}>
									<Lleida extraClass={'Arribar-icon-map ' + (iconClicked == 'Lleida' ? 'icon-clicked' : '')} onClickIcon={setIconClicked} icon={'Lleida'}/>
								</div>
								<div style={{position: 'absolute', top: '35%', left: '80%'}}>
									<Girona extraClass={'Arribar-icon-map ' + (iconClicked == 'Girona' ? 'icon-clicked' : '')} onClickIcon={setIconClicked} icon={'Girona'}/>
								</div>
								<div style={{position: 'absolute', top: '70%', left: '30%'}}>
									<Tarragona extraClass={'Arribar-icon-map ' + (iconClicked == 'Tarragona' ? 'icon-clicked' : '')} onClickIcon={setIconClicked} icon={'Tarragona'}/>
								</div>
								<div style={{position: 'absolute', top: '10%', left: '77%'}}>
									<Perpinya extraClass={'Arribar-icon-map ' + (iconClicked == 'Perpinya' ? 'icon-clicked' : '')} onClickIcon={setIconClicked} icon={'Perpinya'}/>
								</div>
								<div style={{position: 'absolute', top: '0%', left: '50%'}}>
									<Toulouse extraClass={'Arribar-icon-map ' + (iconClicked == 'Toulouse' ? 'icon-clicked' : '')} onClickIcon={setIconClicked} icon={'Toulouse'}/>
								</div>
							</div>
							<div className="Arribar-llista" style={{position: 'relative', width: (props.isMobile ? '100%' : '25%')}}>
								<div className={"Arribar-item " + (iconClicked == 'Casa' ? 'item-clicked' : '')} onClick={()=>{setIconClicked('Casa')}}><Rombo extraClass={'Arribar-rombo ' + (iconClicked == 'Casa' ? 'icon-clicked' : '')}/> El Pinatell</div>
								<div className={"Arribar-item " + (iconClicked == 'Berga' ? 'item-clicked' : '')} onClick={()=>{setIconClicked('Berga')}}><Rombo extraClass={'Arribar-rombo ' + (iconClicked == 'Berga' ? 'icon-clicked' : '')}/> De Berga 8km, 15 minuts</div>
								<div className={"Arribar-item " + (iconClicked == 'Manresa' ? 'item-clicked' : '')} onClick={()=>{setIconClicked('Manresa')}}><Rombo extraClass={'Arribar-rombo ' + (iconClicked == 'Manresa' ? 'icon-clicked' : '')}/> De Manresa 59km, 45 minuts</div>
								<div className={"Arribar-item " + (iconClicked == 'Barcelona' ? 'item-clicked' : '')} onClick={()=>{setIconClicked('Barcelona')}}><Rombo extraClass={'Arribar-rombo ' + (iconClicked == 'Barcelona' ? 'icon-clicked' : '')}/> De Barcelona 115km, 1h 15 minuts</div>
								<div className={"Arribar-item " + (iconClicked == 'Girona' ? 'item-clicked' : '')} onClick={()=>{setIconClicked('Girona')}}><Rombo extraClass={'Arribar-rombo ' + (iconClicked == 'Girona' ? 'icon-clicked' : '')}/> De Girona 131km, 1h 35 minuts</div>
								<div className={"Arribar-item " + (iconClicked == 'Lleida' ? 'item-clicked' : '')} onClick={()=>{setIconClicked('Lleida')}}><Rombo extraClass={'Arribar-rombo ' + (iconClicked == 'Lleida' ? 'icon-clicked' : '')}/> De Lleida 166km, 1h 50 minuts</div>
								<div className={"Arribar-item " + (iconClicked == 'Tarragona' ? 'item-clicked' : '')} onClick={()=>{setIconClicked('Tarragona')}}><Rombo extraClass={'Arribar-rombo ' + (iconClicked == 'Tarragona' ? 'icon-clicked' : '')}/> De Tarragona 169km, 1h 50 minuts</div>
								<div className={"Arribar-item " + (iconClicked == 'Perpinya' ? 'item-clicked' : '')} onClick={()=>{setIconClicked('Perpinya')}}><Rombo extraClass={'Arribar-rombo ' + (iconClicked == 'Perpinya' ? 'icon-clicked' : '')}/> De Perpinyà 221km, 2h 29 minuts</div>
								<div className={"Arribar-item " + (iconClicked == 'Toulouse' ? 'item-clicked' : '')} onClick={()=>{setIconClicked('Toulouse')}}><Rombo extraClass={'Arribar-rombo ' + (iconClicked == 'Toulouse' ? 'icon-clicked' : '')}/> De Toulouse 226km, 3h 10 minuts</div>
							</div>
						</div>
					</div>
					<div className="Preus-separator"></div>

					<GoogleMaps />
				</Box>
			</Box>
            <Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default Arribar;