import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Page from "../../components/layouts/Page";
import { ReactComponent as Icon } from "../../assets/logo_blanc.svg";
import '../../css/views/public/Rutes.css';
import Footer from "../../layouts/public/Footer";

import entorn from '../../../src/assets/img/entorn.jpg';

import useScrollPosition from "../../components/elements/useScrollPosition";
import ScrollToTop from "react-scroll-to-top";
import Idioma from "../../components/elements/Idioma";
import Section from "./Section";
import Place from "./Place";
import PinatellIcon from "../../components/elements/PinatellIcon";
import Ruta from "./Ruta";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Rutes(props) {
	
    const [peu, setPeu] = useState([])
    const [bici, setBici] = useState([])

	const Background = (props) => {
		const [title, setTitle] = useState("Rutes");
        const [open, setOpen] = useState(true);

	const [animation, setAnimation] = useState("");
	const scrollPosition = useScrollPosition();
	const [line, setLine] = useState('');
    const [titleAni, setTitleAni] = useState('');

    

    useEffect(() => {
        window.scrollTo(0, 0);
		setTimeout(() => {
			setLine('Portada-expand');
		}, 500);
        setTimeout(() => {

            setTitleAni('title-expand');
		}, 500);
        
    }, []);

	useEffect(() => {
		let height = window.innerHeight;

		let elementsToShowLeft = document.querySelectorAll(".show-on-scroll-left");
        elementsToShowLeft.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollLeft');
            }
        })
        let elementsToShowRight = document.querySelectorAll(".show-on-scroll-right");
        elementsToShowRight.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollRight');
            }
        })
        
    }, [scrollPosition]);

    function onClickShow() {
        setOpen(!open);
    }

    function onClickSubtitle(id){
        var access = document.getElementById(id);
        access.scrollIntoView({behavior: 'smooth'}, true);
    }

    // const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

		return (
			<Box>
				<div className="Rutes-fixed">
					<Typography variant='h2' className={"Rutes-title-fixed " + animation} maxWidth={'100%'} textAlign='center' textTransform='capitalize' color='#1D1D1B' fontWeight='800'><PinatellIcon extraClass='Pinatell-black'/></Typography>
				</div>
				<div style={{marginBottom: '116px'}}>
					<Box className="Rutes-portada">
						<div className="Home-idiomes">
							<Idioma selected={props.idioma} f_setSelected={props.f_setIdioma} idiomes={props.idiomes}/>
						</div>
						<div className="Rutes-image">
							<img src={entorn} className='Rutes-img'/>
						</div>
						<div className="Rutes-background"></div>
						<Box className={'Rutes-content ' + titleAni}>
							<Typography variant='h1' className="Rutes-title" color='#fff' textTransform='unset'>{props.masia?.rutes_titol}</Typography>
							<div onClick={()=>{onClickSubtitle('peu')}}><Typography variant='h4' className='Rutes-subtitle' color='#fff !important' marginTop='30px' textTransform='unset' fontFamily={'Quicksand'}>{'- ' + props.masia?.rutes_peu}</Typography></div>
							<div onClick={()=>{onClickSubtitle('bici')}}><Typography variant='h4' className='Rutes-subtitle' color='#fff !important' marginTop={props.isMobile ? '10px' : '30px'} textTransform='unset' fontFamily={'Quicksand'}>{'- ' + props.masia?.rutes_bici}</Typography></div>
						</Box>
						<div className={"Portada-line " + line}></div>
					</Box>
					{/* {props.masia?.entorns.map((item, index) => {
						return (
							<Place isLeft={index % 2 == 0 ? true : false} data={item} />
						)
					})} */}
                    <div id='peu' className="Ruta-section show-on-scroll-right">
                        <Typography variant='h2' className="Entorn-title" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.masia?.rutes_peu}</Typography>
                    </div>
                    {props.masia?.rutes.map((item, index) => {
                        if (item.tipus == 'Ruta a peu' || item.tipus == 'Ruta a pie' || item.tipus == 'Randonnée pédestre' || item.tipus == 'Hiking route') {
                            return (
                                <Ruta isLeft={index % 2 == 0 ? true : false} data={item} masia={props.masia}  />
                            )
                        }
                    })}

                    <div id='bici' className="Ruta-section show-on-scroll-right">
                        <Typography variant='h2' className="Entorn-title" textTransform='capitalize' color={'#1D1D1B'} fontWeight='800'>{props.masia?.rutes_bici}</Typography>
                    </div>
                    {props.masia?.rutes.map((item, index) => {
                        if (item.tipus == 'Ruta en bicicleta' || item.tipus == 'Ruta a pie' || item.tipus == 'Randonnée pédestre' || item.tipus == 'Hiking route') {
                            return (
                                <Ruta isLeft={index % 2 == 0 ? true : false} data={item} masia={props.masia} />
                            )
                        }
                    })}
				</div>
			</Box>
		)
	}

	
	return (
		<Page title="El Pinatell">
			<Background peu={peu} bici={bici} masia={props.masia} isMobile={props.isMobile} idioma={props.idioma} f_setIdioma={props.f_setIdioma} idiomes={props.idiomes}/>

            <ScrollToTop smooth/>
			<Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default Rutes;