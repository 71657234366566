import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import { Registre, registreJugador } from "../../../API/API";

import CustomButton from "../../components/elements/CustomButton";
import CustomTextField from "../../components/elements/CustomTextField";
import { sendEmail } from "../../API/API";
import { useSnackbar } from "notistack";

const Contacte = () => {
	const [error, setError] = useState("");
	let navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

	const schema = Yup.object().shape({
		name: Yup.string().max(255).required("El nom és obligatòri"),
		phone: Yup.string().max(255).required("El telèfon és obligatòri"),
		email: Yup.string()
			.email("Ha de ser un email vàlid")
			.max(255)
			.required("L'email és obligatòri"),
		message: Yup.string()
			.max(255)
			.required("El message és obligatòri")
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const enviarContacte = async (values) => {
        const result = await sendEmail('enviarContacte', values);
        enqueueSnackbar(result, {
            variant: result !== 'error' ? 'success' : 'error',
        });
	};

	return (
		<Box sx={{background: '#fff', padding: '3% 20.6%'}}>
			<form onSubmit={handleSubmit(enviarContacte)}>
				<Grid container spacing={2}>
					<Grid item md={12} xs={12}>
						<CustomTextField
							name="name"
							label="Nom"
							type="text"
							errors={errors.name}
							register={register}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item md={12} xs={12}>
						<CustomTextField
							name="email"
							label="E-mail"
							type="email"
							errors={errors.email}
							register={register}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item md={12} xs={12}>
						<CustomTextField
							name="phone"
							label="Telèfon"
							type="text"
							errors={errors.phone}
							register={register}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item md={12} xs={12}>
						<CustomTextField
							name="message"
							label="Missatge"
							type="text"
							errors={errors.message}
							register={register}
						/>
					</Grid>
				</Grid>

				<Typography variant="body1" style={{ color: "red" }}>
					{error}
				</Typography>

				<Box my={3}>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(e) => {}}
								sx={{backgroundColor: '#fff'}}
								name="accepta"
								{...register("accepta")}
								required
							/>
						}
						label={
							<Typography fontFamily={'Quicksand'}>
								He llegit i accepto la{" "}
								<a
									href="/politica-privacitat"
									target="_blank"
									alt="Política de Privacitat"
									rel="noreferrer"
								>
									Política de Privacitat
								</a>{" "}
								i les{" "}
								<a
									href="/reserves"
									target="_blank"
									alt="Política de Privacitat"
									rel="noreferrer"
								>
									Condicions lloguer
								</a>
							</Typography>
						}
					/>
				</Box>

				<Box my={2}>
					<CustomButton type="submit" title="Enviar"/>
				</Box>
			</form>
		</Box>
	);
};

export default Contacte;
