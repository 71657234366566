import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import '../../../css/views/public/Casa.css';
import { ReactComponent as IconInt } from "../../../assets/iconos2.svg";
import useScrollPosition from "../../../components/elements/useScrollPosition";
import Edit from "../Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonAdmin from "../ButtonAdmin";

function SectionEdit(props) {
    const [open, setOpen] = useState(true);
    const [animation, setAnimation] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
		setTimeout(() => {
			setAnimation('Portada-expand');
		}, 500);
        setTimeout(() => {

            setTitle('title-expand');
		}, 500);
        
    }, []);

    const scrollPosition = useScrollPosition();

    useEffect(() => {
        let elementsToShowLeft = document.querySelectorAll(".show-on-scroll-left");
        elementsToShowLeft.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollLeft');
            }
        })
        let elementsToShowRight = document.querySelectorAll(".show-on-scroll-right");
        elementsToShowRight.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollRight');
            }
        })
        
    }, [scrollPosition]);
    
    function onClickShow() {
        setOpen(!open);
    }

    function onClickEliminarEspai(id) {

    }
	
    // const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

    if (props.isLeft) {
        return (
            <Box className='Casa-section' sx={{backgroundColor: ('#152525'), maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 'unset', paddingRight: 'unset'}}>
                <div style={{position: 'absolute', top: '5%', left: '5%', background:'white', padding: '0.5rem 0rem 0.5rem 0.5rem', display: 'flex', justifyContent:'center', alignItems:'center', borderRadius: '0.4rem'}}>
                    <ButtonAdmin 
                        text={''} 
                        icon={<DeleteIcon />} 
                        variant='' 
                        action={() => {onClickEliminarEspai(props.data.id)}} 
                        setOpenDeleteModal={props.setOpenDeleteModal}
                        endPoint={'eliminarEspai'}
                        setEndPoint={props.setEndPoint}
                        id={props.data.id}
                        setId={props.setId}
                        folder={'espai'}
                        setFolder={props.setFolder}
                    />
                </div>
                <div className="Casa-section-left show-on-scroll-left show-on-scroll-left" style={{position: 'relative'}}>
                    
                    <img src={url+props.data?.imatge} className='Casa-img'/>
                    <div style={{position: 'absolute', top: '5%', left: '5%'}}>
                        <Edit endPoint='actualitzarEspai' initialValue={props.data?.imatge} setInitialValue={props.setInitialValue} name={'imatge'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'image'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'espai'} setFolder={props.setFolder}/>
                    </div>
                </div>
                <div className="Casa-section-right show-on-scroll-right show-on-scroll-right">
                    <div style={{display: 'flex'}}>
                        <Typography variant='h2' className="Casa-title" textTransform='capitalize' color={'#fff'} fontWeight='800'>{props.data?.titol}</Typography>
                        <Edit endPoint='actualitzarEspai' initialValue={props.data?.titol} setInitialValue={props.setInitialValue} name={'titol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Títol' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'espai'} setFolder={props.setFolder}/>
                    </div>
                    <div className="Casa-separator"></div>
                    <Typography dangerouslySetInnerHTML={{__html: props.data?.text}}  variant='body1' className='Casa-text' marginBottom='30px' color={'#fff !important'}></Typography>
                    <Edit endPoint='actualitzarEspai' initialValue={props.data?.text} setInitialValue={props.setInitialValue} name={'text'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Títol' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'espai'} setFolder={props.setFolder}/>
                    <IconInt />
                </div>
            </Box>
        )
    } else {
        return (
            <Box className='Casa-section' sx={{backgroundColor: ('#fff'), maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 'unset', paddingRight: 'unset'}}>
                <div style={{position: 'absolute', top: '5%', left: '5%', background:'white', padding: '0.5rem 0rem 0.5rem 0.5rem', display: 'flex', justifyContent:'center', alignItems:'center', borderRadius: '0.4rem'}}>
                    <ButtonAdmin 
                        text={''} 
                        icon={<DeleteIcon />} 
                        variant='' 
                        action={() => {onClickEliminarEspai(props.data.id)}} 
                        setOpenDeleteModal={props.setOpenDeleteModal}
                        endPoint={'eliminarEspai'}
                        setEndPoint={props.setEndPoint}
                        id={props.data.id}
                        setId={props.setId}
                        folder={'espai'}
                        setFolder={props.setFolder}
                    />
                </div>
                <div className="Casa-section-left show-on-scroll-left" style={{textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', maxWidth: (props.isMobile ? '75%' : '32%')}}>
                    <div style={{display: 'flex'}}>
                        <Edit endPoint='actualitzarEspai' initialValue={props.data?.titol} setInitialValue={props.setInitialValue} name={'titol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Títol' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'espai'} setFolder={props.setFolder}/>
                        <Typography variant='h2' className="Casa-title" textTransform='capitalize' color='#1D1D1B' fontWeight='800'>{props.data?.titol}</Typography>
                    </div>
                    <div className="Casa-separator"></div>
                    <Typography dangerouslySetInnerHTML={{__html: props.data?.text}} variant='body1' className='Casa-text' marginBottom='30px'></Typography>
                    <Edit endPoint='actualitzarEspai' initialValue={props.data?.text} setInitialValue={props.setInitialValue} name={'text'} setName={props.setName} setEndPoint={props.setEndPoint} color='#1D1D1B' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Títol' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'espai'} setFolder={props.setFolder}/>
                    <IconInt />
                </div>
                <div className="Casa-section-right show-on-scroll-right" style={{position: 'relative'}}>
                    <img src={url+props.data?.imatge} className='Casa-img'/>
                    <div style={{position: 'absolute', top: '5%', right: '5%'}}>
                        <Edit endPoint='actualitzarEspai' initialValue={props.data?.imatge} setInitialValue={props.setInitialValue} name={'imatge'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'image'} setType={props.setType} label='Imatge' setLabel={props.setLabel} id={props.data?.id} setId={props.setId} folder={'espai'} setFolder={props.setFolder}/>
                    </div>
                </div>
            </Box>
	    );
    }
	
}

export default SectionEdit;
