import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import '../../css/views/public/Casa.css';
import { ReactComponent as IconInt } from "../../assets/iconos2.svg";
import useScrollPosition from "../../components/elements/useScrollPosition";

function Section(props) {
    const [open, setOpen] = useState(true);
    const [animation, setAnimation] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
		setTimeout(() => {
			setAnimation('Portada-expand');
		}, 500);
        setTimeout(() => {

            setTitle('title-expand');
		}, 500);
        
    }, []);

    const scrollPosition = useScrollPosition();

    useEffect(() => {
        let elementsToShowLeft = document.querySelectorAll(".show-on-scroll-left");
        elementsToShowLeft.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollLeft');
            }
        })
        let elementsToShowRight = document.querySelectorAll(".show-on-scroll-right");
        elementsToShowRight.forEach((element, index) => {
            if (element.getBoundingClientRect().top <= window.innerHeight-(window.innerHeight/2)) {
                element.classList.add('showOnScrollRight');
            }
        })
        
    }, [scrollPosition]);
    
    function onClickShow() {
        setOpen(!open);
    }
	
    // const url = 'http://127.0.0.1:8000/storage/';
    const url = 'https://api.masiaelpinatell.com/public/storage/';

    if (props.isLeft) {
        return (
            <Box className='Casa-section' sx={{backgroundColor: ('#152525'), maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 'unset', paddingRight: 'unset'}}>
                <div className="Casa-section-left show-on-scroll-left show-on-scroll-left">
                    
                    <img src={url+props.data?.imatge} className='Casa-img'/>
                    
                </div>
                <div className="Casa-section-right show-on-scroll-right show-on-scroll-right">
                    <Typography variant='h2' className="Casa-title" textTransform='capitalize' color={'#fff'} fontWeight='800'>{props.data?.titol}</Typography>
                    <div className="Casa-separator"></div>
                    <Typography variant='body1' className='Casa-text' marginBottom='30px' color={'#fff !important'}>{props.data?.text}</Typography>
                    <IconInt />
                </div>
            </Box>
        )
    } else {
        return (
            <Box className='Casa-section' sx={{backgroundColor: ('#fff'), maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 'unset', paddingRight: 'unset'}}>
                <div className="Casa-section-left show-on-scroll-left" style={{textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <Typography variant='h2' className="Casa-title" textTransform='capitalize' color='#1D1D1B' fontWeight='800'>{props.data?.titol}</Typography>
                    <div className="Casa-separator"></div>
                    <Typography variant='body1' className='Casa-text' marginBottom='30px'>{props.data?.text}</Typography>
                    <IconInt />
                </div>
                <div className="Casa-section-right show-on-scroll-right" style={{position: 'relative'}}>
                    <img src={url+props.data?.imatge} className='Casa-img'/>
                </div>
            </Box>
	    );
    }
	
}

export default Section;
