import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Preus.css';
import Footer from "../../layouts/public/Footer";
import Calendari from "../../components/elements/Calendari";

function Avis(props) {

	
	return (
		<Page title="El Pinatell">
			<Box className='Preus-portada'>
				<Box className='Preus-padding' padding={props.isMobile ? '15% 0' : '10% 20.6% 5%'}>
					<Typography variant="h2" className="Preus-title">Avís Legal</Typography>
					{/* <div className="Preus-separator"></div> */}
					<Box className="Preus-content" sx={{display: 'block'}}>
						{/* <Typography variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}>Ballús, SA manté aquest lloc web amb l’objectiu de facilitar l’accés a informació corporativa i no corporativa actualitzada i exacta. Tractarem de corregir els errors que se’ns assenyali amb la màxima celeritat possible. No obstant, Ballús, SA no pot garantir la inexistència d’errors ni que el contingut de la informació es trobi permanentment actualitzat.</Typography>
                        <Typography variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}>En conformitat a l’establert a les lleis 15/1999 (Protecció de Dades de Caràcter Personal) i 34/2002 (Serveis de la Societat de la Informació i del Comerç Electrònic) s’informa del següent:</Typography>
						 */}
					</Box>
				</Box>
                <Box className='Preus-white'>
                    
                    <Box className='Preus-padding'>
                        <Box className='Preus-condicions'>
                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px'>1. OBJETO</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>El presente aviso legal regula el uso y utilización del sitio web www.masiaelpinatell.com, del que es titular BALLUS SA (en adelante, EL PROPIETARIO DE LA WEB).</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>La navegación por el sitio web de EL PROPIETARIO DE LA WEB le atribuye la condición de USUARIO del mismo y conlleva su aceptación plena y sin reservas de todas y cada una de las condiciones publicadas en este aviso legal, advirtiendo de que dichas condiciones podrán ser modificadas sin notificación previa por parte de EL PROPIETARIO DE LA WEB, en cuyo caso se procederá a su publicación y aviso con la máxima antelación posible.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Por ello es recomendable leer atentamente su contenido en caso de desear acceder y hacer uso de la información y de los servicios ofrecidos desde este sitio web.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>El usuario, además, se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal, y responderá frente a EL PROPIETARIO DE LA WEB o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Cualquier utilización distinta a la autorizada está expresamente prohibida, pudiendo EL PROPIETARIO DE LA WEB denegar o retirar el acceso y su uso en cualquier momento.</Typography>
                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px' marginTop='30px'>2. IDENTIFICACIÓN</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>EL PROPIETARIO DE LA WEB, en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, le informa de que:</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>· Su denominación social es: BALLUS SA</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>· Su CIF es: A08013906</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>· Su domicilio social está en: PASSEIG LA PAU, S/N - 08600 - BERGA - BARCELONA</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>· Inscrita en el Registro Mercantil de Barcelona Folio 78, tomo 4554, libro 3880, sección </Typography>
                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px' marginTop='30px'>3. COMUNICACIONES</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos a continuación:</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>· Tfno.: 621 14 42 95</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>· Email: info@masiaelpinatell.com</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Todas las notificaciones y comunicaciones entre los usuarios y PROPIETARIO DE LA WEB se considerarán eficaces, a todos los efectos, cuando se realicen a través de cualquier medio de los detallados anteriormente.</Typography>
                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px' marginTop='30px'>4. CONDICIONES DE ACCESO Y UTILIZACIÓN</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>El sitio web y sus servicios son de acceso libre y gratuito. No obstante, PROPIETARIO DE LA WEB puede condicionar la utilización de algunos de los servicios ofrecidos en su web a la previa cumplimentación del correspondiente formulario.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a PROPIETARIO DE LA WEB y será el único responsable de las manifestaciones falsas o inexactas que realice.</Typography>  
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios de PROPIETARIO DE LA WEB y a no emplearlos para, entre otros:</Typography>  
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"a) Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de apología del terrorismo o, en general, contrarios a la ley o al orden público."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"b) Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de PROPIETARIO DE LA WEB o de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales PROPIETARIO DE LA WEB presta sus servicios."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"c) Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de PROPIETARIO DE LA WEB o de terceros y, en su caso, extraer información."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"d) Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de PROPIETARIO DE LA WEB o de terceros."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"e) Suplantar la identidad de cualquier otro usuario."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"f) Reproducir, copiar, distribuir, poner a disposición de, o cualquier otra forma de comunicación pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"g) Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento."}</Typography>

                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos, tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a PROPIETARIO DE LA WEB, sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web.</Typography>    
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>En definitiva, los usuarios que accedan a este sitio web pueden visualizar los contenidos y efectuar, en su caso, copias privadas autorizadas siempre que los elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen a servidores conectados a redes, ni sean objeto de ningún tipo de explotación.</Typography>    
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de PROPIETARIO DE LA WEB, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos.</Typography>    
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación quedan prohibidos.</Typography>    
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>El establecimiento de un hiperenlace no implica en ningún caso la existencia de relaciones entre PROPIETARIO DE LA WEB y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación por parte de PROPIETARIO DE LA WEB de sus contenidos o servicios.</Typography>    
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>PROPIETARIO DE LA WEB no se responsabiliza del uso que cada usuario les dé a los materiales puestos a disposición en este sitio web ni de las actuaciones que realice en base a los mismos.</Typography>    

                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px' marginTop='30px' fontSize={'1.5em'}>4.1. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD EN EL ACCESO Y LA UTILIZACIÓN</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>El contenido del presente sitio web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>PROPIETARIO DE LA WEB excluye, hasta donde permite el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de:</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"a) La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición, a los que se haya accedido a través del sitio web o de los servicios que se ofrecen."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"b) La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los usuarios."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' marginLeft={'20px'}>{"c) El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente aviso legal como consecuencia del uso incorrecto del sitio web. En particular, y a modo ejemplificativo, PROPIETARIO DE LA WEB no se hace responsable de las actuaciones de terceros que vulneren derechos de propiedad intelectual e industrial, secretos empresariales, derechos al honor, a la intimidad personal y familiar y a la propia imagen, así como la normativa en materia de competencia desleal y publicidad ilícita."}</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Asimismo, PROPIETARIO DE LA WEB declina cualquier responsabilidad respecto a la información que se halle fuera de esta web y no sea gestionada directamente por nuestro webmaster. La función de los links que aparecen en esta web es exclusivamente la de informar al usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece este sitio web. PROPIETARIO DE LA WEB no garantiza ni se responsabiliza del funcionamiento o accesibilidad de los sitios enlazados; ni sugiere, invita o recomienda la visita a los mismos, por lo que tampoco será responsable del resultado obtenido. PROPIETARIO DE LA WEB no se responsabiliza del establecimiento de hipervínculos por parte de terceros.</Typography>  

                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px' marginTop='30px' fontSize={'1.5em'}>4.2. PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>En el caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias que revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización de cualquier actividad en las páginas web incluidas o accesibles a través del sitio web, deberá enviar una notificación a PROPIETARIO DE LA WEB identificándose debidamente y especificando las supuestas infracciones.</Typography>      

                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px' marginTop='30px' fontSize={'1.5em'}>4.3. PUBLICACIONES</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>La información administrativa facilitada a través del sitio web no sustituye la publicidad legal de las leyes, normativas, planes, disposiciones generales y actos que tengan que ser publicados formalmente a los diarios oficiales de las administraciones públicas, que constituyen el único instrumento que da fe de su autenticidad y contenido. La información disponible en este sitio web debe entenderse como una guía sin propósito de validez legal.</Typography> 

                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px' marginTop='30px'>5. LEGISLACIÓN APLICABLE</Typography>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>Las condiciones presentes se regirán por la legislación española vigente. La lengua utilizada será el Castellano.</Typography> 

                        </Box>
                    </Box>
                </Box>
			</Box>
            <Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default Avis;