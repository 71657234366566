import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	AppBar,
	Box,
	Drawer,
	Hidden,
	IconButton,
	Toolbar,
	useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavBarItem from "./NavBarItem";
import { Close } from "@mui/icons-material";
import NavBarItemMobile from "./NavBarItemMobile";
import clsx from "clsx";
import { ReactComponent as Icon } from "../../assets/menu.svg";
import CustomButton from "../../components/elements/CustomButton";
import { useNavigate } from "react-router";
import Menu from "./Menu";
import PinatellIcon from "../../components/elements/PinatellIcon";
import useScrollPosition from "../../components/elements/useScrollPosition";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeButton from "../../components/elements/HomeButton";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		zIndex: 100,
		paddingBottom: 20,
		position: "fixed",
	},
	nav: {
		transition: "0.2s",
	},
	navBar: {
		backgroundColor: "white !important",
		boxShadow: "none",
		position:"relative",
	},
	navBarDown: {
		backgroundColor: "transparent",
		boxShadow: "none",
		maskSize: "cover",
		maskRepeat: "no-repeat",
		maskPosition: "center",
	},
	logo: {
		fill: theme.color.primary,
	},
	maskTop: {
		// backgroundImage: `url(${bg})`,
		width: "100%",
	},
}));

const TopBarPublic = ({ hideMenu, setHideMenu, isMobile, className, idioma, f_setIdioma, idiomes, masia, ...rest }) => {
	const matches = useMediaQuery("(min-width:960px)");
	const matches2 = useMediaQuery("(min-width:2000px)");
	const classes = useStyles();
	const [menu, setMenu] = useState(true);
	const [colorLogo, setColorLogo] = useState('');
    const [colorMenu, setColorMenu] = useState('');
	const [translateLogo, setTranslateLogo] = useState('logo-initial');
	const [scrollY, setScrollY] = useState(window.scrollY);
	const navigate = useNavigate();

	useEffect(() => {
		if (window.location.pathname == '/')  {
			setMenu(true);
			setTranslateLogo(' logo-initial');
			setColorMenu(' menu-transparent');
			setColorLogo(' Menu-logo-white');
		}
		else if (window.location.pathname == '/contacte' || window.location.pathname == '/reserves' || window.location.pathname == '/com-arribar' || window.location.pathname == '/politica-cookies' || window.location.pathname == '/politica-privacitat' || window.location.pathname == '/avis-legal') {
			setMenu(false);
			setTranslateLogo('logo-translated');
			setColorLogo(' Menu-logo-black');
			setTimeout(() => {
				setColorMenu(' menu-white');
			}, 400);
		}
		else {
			setMenu(false);
			setTranslateLogo(' logo-initial');
			setColorMenu(' menu-transparent');
			setColorLogo(' Menu-logo-white');
		}
		
        
    }, [window.location.pathname]);

	const clickMenu = () => {
		if (hideMenu) setHideMenu(false);
		if (menu) {
			setMenu(false);
			if (window.location.pathname == '/contacte' || window.location.pathname == '/reserves' || window.location.pathname == '/com-arribar' || window.location.pathname == '/politica-cookies' || window.location.pathname == '/politica-privacitat' || window.location.pathname == '/avis-legal') {
				setTranslateLogo('logo-translated');
				setColorLogo(' Menu-logo-black');
				setTimeout(() => {
					setColorMenu(' menu-white');
				}, 400);
			}
			else {
				if (scrollPosition > 0) {
					setTranslateLogo('logo-translated');
					setColorLogo(' Menu-logo-black');
					setTimeout(() => {
						setColorMenu(' menu-white');
					}, 400);
				}
				else {
					setTranslateLogo(' logo-initial');
					setColorMenu(' menu-transparent');
					setColorLogo(' Menu-logo-white');
				}
			}
			
		} else {

			setMenu(true);
			setTimeout(() => {
				setTranslateLogo('logo-initial');
				setColorMenu(' menu-transparent');
				setColorLogo('Menu-logo-white');
			}, 600);
		}
	}

	const scrollPosition = useScrollPosition();

    useEffect(() => {
		if (!menu) {
			if (scrollPosition > 0 && translateLogo != ' logo-translated' && window.location.pathname != '/contacte' && window.location.pathname != '/reserves' && window.location.pathname != '/com-arribar' && window.location.pathname != '/politica-cookies' && window.location.pathname != '/politica-privacitat' && window.location.pathname != '/avis-legal') {
				setTranslateLogo(' logo-translated');
				setColorMenu(' menu-white');
				setColorLogo(' Menu-logo-black');
			} else if (scrollPosition == 0 && window.location.pathname != '/contacte' && window.location.pathname != '/reserves' && window.location.pathname != '/com-arribar' && window.location.pathname != '/politica-privacitat' && window.location.pathname != '/politica-cookies' && window.location.pathname != '/avis-legal') {
				setTranslateLogo(' logo-initial');
				setColorMenu(' menu-transparent');
				setColorLogo(' Menu-logo-white');
			}
		}
        
    }, [scrollPosition]);

	useEffect(() => {
		if (window.location.pathname == '/') {
			setMenu(true);
			setTranslateLogo(' logo-initial');
			setColorMenu(' menu-transparent');
			setColorLogo(' Menu-logo-white');
		}
        
    }, [window.location.pathname]);

	const clickButton = (logoInitial) => {
        setMenu(false);
		if (!logoInitial) {
			setTranslateLogo('logo-translated');
			setColorLogo(' Menu-logo-black');
			setTimeout(() => {
				setColorMenu(' menu-white');
			}, 400);
		}
		else {
			if (scrollPosition > 0) {
				setTranslateLogo('logo-translated');
				setColorLogo(' Menu-logo-black');
				setTimeout(() => {
					setColorMenu(' menu-white');
				}, 400);
			}
			else {
				setTranslateLogo(' logo-initial');
				setColorMenu(' menu-transparent');
				setColorLogo(' Menu-logo-white');
			}
		}
    }


	return (
		<Box className={classes.main}>
			<div className={'Menu-header ' + colorMenu}>
				<Toolbar sx={{height: '100%', display: 'flex', justifyContent: 'space-between'}}>	
					<div style={{width: '5%', height: '6vh', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', zIndex:'11'}} onClick={clickMenu}>
						{window.location.pathname != '/' ? (menu ? <CloseIcon htmlColor={colorMenu == ' menu-white' ? '#1e1e1e' : '#fff'}/> : <MenuIcon htmlColor={colorMenu == ' menu-white' ? '#1e1e1e' : '#fff'}/>) : null}
					</div>
					<div 
						id="LogoPage" 
						onClick={()=>{
							navigate('/');
							//clickMenu()
						}} 
						className={"MenuPinatell-logo " + translateLogo + ' ' + (hideMenu ? 'hide-menu' : 'show-menu')}
					>
						<PinatellIcon width={'200px'} extraClass={"Menu-logo "+colorLogo}/>
					</div>
					<div style={{width: (isMobile ? '15%' : '5%'), height: '6vh', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', minWidth: '100px'}}>
						{colorMenu == ' menu-white' ? <HomeButton text='contacte' f_clickMenu={clickButton} link='contacte' extraClass={'button-black'}/> : null}
					</div>
				</Toolbar>
				
				<Menu masia={masia} open={menu} f_clickMenu={clickButton} idioma={idioma} f_setIdioma={f_setIdioma} idiomes={idiomes}/>

			</div>
		</Box>
	);
};

TopBarPublic.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBarPublic;
