import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import '../../../css/views/public/Home.css';
import '../../../css/layouts/public/Menu.css';
import image1 from '../../../assets/img/fotos/30.jpg';
import image2 from '../../../assets/img/fotos/31.jpg';
import image3 from '../../../assets/img/fotos/32.jpg';
import image4 from '../../../assets/img/fotos/33.jpg';
import image5 from '../../../assets/img/fotos/34.jpg';
import { useNavigate } from 'react-router';
import HomeButton from "../../../components/elements/HomeButton";
import PinatellPortada from "../../../components/elements/PinatellPortada";
import InstagramIcon from '@mui/icons-material/Instagram';
import GoogleIcon from '@mui/icons-material/Google';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Idioma from "../../../components/elements/Idioma";
import EditIcon from '@mui/icons-material/Edit';
import ButtonAdmin from '../ButtonAdmin';
import Edit from "../Edit";



const PortadaEdit = (props) => {

	const [images, setImages] = useState([image1, image2, image3, image4, image5])
	const [animation, setAnimation] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
        setTimeout(() => {
            setAnimation(true);
        }, 1000);
        let elementsToShow = document.querySelectorAll(".show-on-open");
        setTimeout(() => {
            elementsToShow.forEach((element, index) => {
                element.classList.add('show-item-menu'+index);
            })
        }, 1000);
    }, []);

	useEffect(() => {
        let imatges = props.masia?.imatges;
		let aux = [];
		for (let i=0 ; i < imatges?.length ; i++) {
			if (imatges[i].tipus == 'portada') {
				aux.push(imatges[i])
			}
		}
		setImages(aux);
    }, [props.masia]);


	return (
		<Box className={"Menu-container " + (true? 'menu-opened' : 'menu-closed')} sx={{width: '100%', position: 'relative'}}>
			<Box className='Home-portada'>
				<div className="Home-image">
					{/* <img src={image} className='Home-img'/> */}
					<PinatellPortada images={images}/>
				</div>
				<div className="Home-background"></div>
				<Box className='Home-content'>
					{/* <Icon
						alt="Logo Pinatell"
						className='Home-logo'
						width={'100%'}
					/> */}
					<div className={"Home-separator " + (animation ? 'expand-separator' : 'not-expand-separator')}></div>
					<div className="Home-title">{props.masia?.menu_titol}</div>
					<Edit endPoint='actualitzarMasia' initialValue={props.masia?.menu_titol} setInitialValue={props.setInitialValue} name={'menu_titol'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
					<div className="Home-buttons">
						<div className={"HomeButton-container show-on-open"}>
							<div className="HomeButton-text">{props.masia?.menu_item1}</div>
							<Edit endPoint='actualitzarMasia' initialValue={props.masia?.menu_item1} setInitialValue={props.setInitialValue} name={'menu_item1'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						</div>
						<div className={"HomeButton-container show-on-open"}>
							<div className="HomeButton-text">{props.masia?.menu_item2}</div>
							<Edit endPoint='actualitzarMasia' initialValue={props.masia?.menu_item2} setInitialValue={props.setInitialValue} name={'menu_item2'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						</div>
						<div className={"HomeButton-container show-on-open"}>
							<div className="HomeButton-text">{props.masia?.menu_item3}</div>
							<Edit endPoint='actualitzarMasia' initialValue={props.masia?.menu_item3} setInitialValue={props.setInitialValue} name={'menu_item3'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						</div>
						<div className={"HomeButton-container show-on-open"}>
							<div className="HomeButton-text">{props.masia?.menu_item4}</div>
							<Edit endPoint='actualitzarMasia' initialValue={props.masia?.menu_item4} setInitialValue={props.setInitialValue} name={'menu_item4'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						</div>
						<div className={"HomeButton-container show-on-open"}>
							<div className="HomeButton-text">{props.masia?.menu_item5}</div>
							<Edit endPoint='actualitzarMasia' initialValue={props.masia?.menu_item5} setInitialValue={props.setInitialValue} name={'menu_item5'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						</div>
						<div className={"HomeButton-container show-on-open"}>
							<div className="HomeButton-text">{props.masia?.menu_item6}</div>
							<Edit endPoint='actualitzarMasia' initialValue={props.masia?.menu_item6} setInitialValue={props.setInitialValue} name={'menu_item6'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						</div>
						<div className={"HomeButton-container show-on-open"}>
							<div className="HomeButton-text">{props.masia?.menu_item7}</div>
							<Edit endPoint='actualitzarMasia' initialValue={props.masia?.menu_item7} setInitialValue={props.setInitialValue} name={'menu_item7'} setName={props.setName} setEndPoint={props.setEndPoint} color='#fff' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Text' setLabel={props.setLabel} id={props.masia?.id} setId={props.setId} folder={'masia'} setFolder={props.setFolder}/>
						</div>
					</div>
					<div style={{marginTop: '10px', display: 'flex', justifyContent:'center'}}>
						<Typography variant="footer" className="Footer-text">
							<a href="https://www.instagram.com/masiaelpinatell/">
								<InstagramIcon />
							</a>
						</Typography>
						<Typography variant="footer" className="Footer-text" marginLeft={'10px'}>
							<a href="https://goo.gl/maps/x3xqwuWgusDJmcjx5">
								<GoogleIcon />
							</a>
						</Typography>
						<Typography variant="footer" className="Footer-text" marginLeft={'10px'}>
							<a href="mailto:info@masiaelpinatell.com">
								<MailOutlineIcon />
							</a>
						</Typography>
					</div>
				</Box>
				<div className="Home-idiomes">
					<Idioma selected={props.idioma} f_setSelected={props.f_setIdioma} idiomes={props.idiomes}/>
				</div>
				<div style={{position: 'absolute', top: '15%', left: '5%', color: 'white', border: '1px solid white', background:'transparent', padding: '0.5rem 0rem 0.5rem 0.5rem', display: 'flex', justifyContent:'center', alignItems:'center', borderRadius: '0.4rem'}}>
                    <ButtonAdmin 
                        text={'Gestionar imatges'} 
                        icon={<EditIcon />} 
                        variant='' 
                        action={() => {navigate('imatges/portada')}}
                    />
                </div>
				
			</Box>
		</Box>
	);
}

export default PortadaEdit;
